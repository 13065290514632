<template>
  <div>
    <Banner></Banner>
    <LighthouseSection></LighthouseSection>
    <AutomationSection></AutomationSection>
    <!-- <PlatformSection></PlatformSection> -->
    <Pricing></Pricing>
    <ContactUs></ContactUs>
  </div>
</template>

<script>
import Banner from '../landing-pages/home/Banner.vue'
import LighthouseSection from '../landing-pages/home/LighthouseSection.vue'
import AutomationSection from '../landing-pages/home/AutomationSection.vue'
// import PlatformSection from '../landing-pages/home/PlatformSection.vue'
import ContactUs from '../landing-pages/home/ContactUs.vue'
import Pricing from '../landing-pages/home/Pricing.vue'
export default {
  name: 'Home',
  components: {
    Banner,
    LighthouseSection,
    AutomationSection,
    // PlatformSection,
    ContactUs,
    Pricing,
  },
}
</script>
