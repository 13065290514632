import VueRouter from 'vue-router'
// Pages
import ITStartup from './components/landing-pages/Home'
import Developer from './components/landing-pages/Developer'
import WebHosting from './components/landing-pages/WebHosting'
import RepairCenter from './components/landing-pages/RepairCenter'
import Iot from './components/landing-pages/Iot'
import Zapier from './components/landing-pages/Zapier'
import Integromat from './components/landing-pages/Integromat'
import MachineLearning from './components/landing-pages/MachineLearning'
import InvoiceProcessing from './components/landing-pages/InvoiceProcessing'
import DigitalAgency from './components/landing-pages/DigitalAgency'
import AboutStyleOne from './components/other-pages/about/AboutStyleOne'
import AboutStyleTwo from './components/other-pages/about/AboutStyleTwo'
import AboutStyleThree from './components/other-pages/about/AboutStyleThree'
import Features from './components/other-pages/features/Features'
import FeatureDetails from './components/other-pages/features/FeatureDetails'
import ServicesOne from './components/other-pages/services/ServicesOne'
import ServicesTwo from './components/other-pages/services/ServicesTwo'
import ServicesThree from './components/other-pages/services/ServicesThree'
import ServicesFour from './components/other-pages/services/ServicesFour'
import ServicesFive from './components/other-pages/services/ServicesFive'
import ServiceDetails from './components/other-pages/services/ServiceDetails'
import ProjectStyleOne from './components/other-pages/projects/ProjectStyleOne'
import ProjectStyleTwo from './components/other-pages/projects/ProjectStyleTwo'
import ProjectDetails from './components/other-pages/projects/ProjectDetails'
import Team from './components/other-pages/team/Team'
import Pricing from './components/other-pages/pricing/Pricing'
import Faq from './components/other-pages/faq/Faq'
import NotFound from './components/other-pages/not-found/NotFound'
import ComingSoon from './components/other-pages/coming-soon/ComingSoon'
import BlogGrid from './components/other-pages/blog/BlogGrid'
import BlogRightSidebar from './components/other-pages/blog/BlogRightSidebar'
import BlogGridTwo from './components/other-pages/blog/BlogGridTwo'
import BlogRightSidebarTwo from './components/other-pages/blog/BlogRightSidebarTwo'
import BlogGridThree from './components/other-pages/blog/BlogGridThree'
import BlogRightSidebarThree from './components/other-pages/blog/BlogRightSidebarThree'
import BlogDetails from './components/other-pages/blog/BlogDetails'
import Contact from './components/other-pages/contact/Contact'
import Shop from './components/other-pages/product/Shop'
import Cart from './components/other-pages/product/Cart'
import ItemDetails from './components/other-pages/product/ItemDetails'
import Onboarder from './components/other-pages/onboarder/Onboarder'
import NewAccount from './components/other-pages/onboarder/NewAccount'
import CMSPackageNotFound from './components/other-pages/not-found/CMSPackageNotFound'
import Support from './components/other-pages/support/Support'

export const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', component: ITStartup },
    { path: '/developer', component: Developer },
    { path: '/web-hosting', component: WebHosting },
    { path: '/repair-center', component: RepairCenter },
    { path: '/invoiceProcessing', component: InvoiceProcessing },
    { path: '/iot', component: Iot },
    { path: '/zapier', component: Zapier },
    { path: '/make', component: Integromat },
    { path: '/machine-learning', component: MachineLearning },
    { path: '/digital-agency', component: DigitalAgency },
    { path: '/about-style-one', component: AboutStyleOne },
    { path: '/about-style-two', component: AboutStyleTwo },
    { path: '/about-style-three', component: AboutStyleThree },
    { path: '/features', component: Features },
    { path: '/feature-details', component: FeatureDetails },
    { path: '/support', component: Support },
    { path: '/service-style-one', component: ServicesOne },
    { path: '/service-style-two', component: ServicesTwo },
    { path: '/service-style-three', component: ServicesThree },
    { path: '/service-style-four', component: ServicesFour },
    { path: '/service-style-five', component: ServicesFive },
    { path: '/service-details', component: ServiceDetails },
    { path: '/project-style-one', component: ProjectStyleOne },
    { path: '/project-style-two', component: ProjectStyleTwo },
    { path: '/project-details', component: ProjectDetails },
    { path: '/team', component: Team },
    { path: '/pricing', component: Pricing },
    { path: '/faq', component: Faq },
    { path: '/coming-soon', component: ComingSoon },
    { path: '/not-found', component: NotFound },
    { path: '/blog-grid', component: BlogGrid },
    { path: '/blog-right-sidebar', component: BlogRightSidebar },
    { path: '/blog-grid-two', component: BlogGridTwo },
    { path: '/blog-right-sidebar-two', component: BlogRightSidebarTwo },
    { path: '/blog-grid-three', component: BlogGridThree },
    { path: '/blog-right-sidebar-three', component: BlogRightSidebarThree },
    { path: '/blog-details', component: BlogDetails },
    { path: '/contact', component: Contact },
    { path: '/shop', component: Shop },
    { path: '/cart', component: Cart },
    {
      path: '/Register',
      component: Onboarder,
      props: { typeOfService: 'marketplace' },
    },
    {
      path: '/signup',
      beforeEnter() {
        // Put the full page URL including the protocol http(s) below
        window.location.replace('https://cloudify.biz/marketplace/register')
      },
    },
    { path: '/details', component: ItemDetails },
    { path: '/new-account', component: NewAccount },
    { path: '/package-not-found', component: CMSPackageNotFound },
  ],
})
