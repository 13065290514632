
import {countryCode} from '../constants/countryCodes'

export const prefixCountryCode = function (vatNumber, country) {
    const selectedCountryCode = countryCode[country]

    const firstTwoCharactersOfVAT = vatNumber.substring(0,2)
    
    if(firstTwoCharactersOfVAT !== selectedCountryCode) {
      return selectedCountryCode + vatNumber
    } else return vatNumber
}