<template>
  <div>
    <div class="features-banner-bg">
      <div class="container">
        <div class="features-content-cont">
          <h1>Why do you need Lighthouse?</h1>
          <p>
            We help you become a more connected business where you get instant
            solutions, feedback, and access to everything that will let you
            solve your issues.
          </p>
        </div>
      </div>
    </div>
    <div class="features-line cont1"></div>
    <div class="features-line cont2"></div>
  </div>
</template>

<script>
export default {
  name: 'BannerSection',
}
</script>

<style scoped>
.features-banner-bg {
  background-color: #0a0b36;
  position: relative;
  padding-top: 200px;
  padding-bottom: 150px;
}
.features-content-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features-content-cont h1 {
  color: #ffffff;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
}
.features-content-cont p {
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  max-width: 520px;
  opacity: 0.75;
  color: #ffffff;
  text-align: center;
}
.features-line {
  height: 14px;
  background-color: #0a0b36;
}
.cont1 {
  opacity: 0.5;
}
.cont2 {
  opacity: 0.2;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .features-content-cont h1 {
    font-size: 42px;
  }
  .features-content-cont p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .features-content-cont h1 {
    font-size: 36px;
  }
  .features-content-cont p {
    font-size: 16px;
  }
}
</style>
