<template>
  <div class="zapier-features-cont">
    <div class="container">
      <h2 class="heading">Why Lighthouse by Cloudify?</h2>

      <ul class="row mt-5 unordered-list">
        <li
          v-for="item in LBCData"
          :key="item.index"
          class="col-lg-6 col-md-6 col-sm-12"
        >
          <div class="card-cont">
            <div class="d-flex flex-row">
              <img
                :src="require(`@/assets/img/zapier/${item.image}`)"
                class="card-image"
                alt="image"
              />
              <div class="card-content ml-3">
                <h3 class="card-heading">{{ item.title }}</h3>
                <p class="card-para">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Features',
  data() {
    return {
      LBCData: [
        {
          title: 'Retriggering failed automation',
          image: 'icon-retrigger.svg',
          description:
            'This is Cloudify’s ultimate USP, where you can select the failed automation and click on retrigger and your workflow gets running again. This can be done by the client with no coding knowledge.',
        },
        {
          title: 'Real time error reporting',
          image: 'icon-real-time-error.svg',
          description:
            'When deals with zero values fail, they are reported immediately to the customer. The failed entry is also reported to the Cloudify team for further action.',
        },
        {
          title: 'Active monitoring',
          image: 'icon-active-monitoring.svg',
          description:
            'Add your workflow name in Lighthouse by Cloudify, mention the apps that you have integrated, and have a look at the workflow entities created with Integromat.',
        },
        {
          title: 'Managed pricing',
          image: 'icon-managed-pricing.svg',
          description:
            'Based on your requirements and the add-ons and subscriptions, we offer you the best-customised pricing that falls within your budget, meets your automation needs, and is affordable at the same time.',
        },
        {
          title: 'Customised/Managed automation',
          image: 'icon-customized-automation.svg',
          description:
            'We offer business-specific automation based on your specific business needs that involves complex business processes with multiple app integrations.',
        },
        {
          title: 'DIY automation',
          image: 'icon-diy-integrations.svg',
          description:
            'If you are a small enterprise that needs to automate certain workflows that require a lot of manual work and time of your employees, this is the best option for you. Here, you can opt for pre-built workflows based on your business needs.',
        },
      ],
    }
  },
}
</script>

<style scoped>
.zapier-features-cont {
  text-align: center;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 60px;
}
.heading {
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: #051555;
}
.card-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(239, 239, 239, 0.86);
  padding: 20px;
  min-height: 180px;
  margin-top: 20px;
  border-radius: 3px;
  transition: transform 200ms;
}
.card-cont:hover {
  box-shadow: 0px 4px 16px 0px #bfbfbf;
}
.card-content {
  text-align: start;
}
.card-heading {
  font-weight: 600;
  font-size: 16px;
  color: #051555;
}
.card-para {
  font-weight: 400;
  font-size: 14px;
  color: #627792;
}
.card-image {
  width: 25%;
  align-self: flex-start;
}
.unordered-list {
  list-style-type: none;
  padding: 0px;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 27px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .card-cont {
    min-height: 300px;
  }
  .heading {
    font-size: 30px;
  }
}
</style>
