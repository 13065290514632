<template>
  <div class="order-table table-responsive">
    <table class="table table-bordered">
      <thead></thead>

      <tbody>
        <tr>
          <td scope="col" class="plan-details-key">
            <span>Plan Name</span>
          </td>
          <td scope="col">
            <b-form-select
              v-model="selectedPlan"
              :options="plans"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>
        <!-- Selected Workflow -->
        <tr>
          <td scope="col" class="plan-details-key">
            <span>Workflow</span>
          </td>
          <td scope="col" class="plan-details-value">
            <b-form-select
              @change="workflowDiscriptionUpdater"
              v-model="workflow"
              :options="workflowList"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>
        <!-- Workflow Description -->
        <tr>
          <td scope="col" class="plan-details-key">
            <span>Workflow's Description</span>
          </td>
          <td scope="col" class="plan-details-valuee">
            <p>{{ workflowDiscrption }}</p>
          </td>
        </tr>
        <!-- Select First App Involved -->
        <tr>
          <td class="plan-details-key" scope="col">
            <span>Select First Application</span>
          </td>
          <td class="plan-details-value" scope="col">
            <b-row>
              <b-col>
                <b-form-select
                  @change="triggerAppChanged()"
                  v-model="selectedTriggerApp"
                  :options="supportedApps"
                  class="select-el"
                ></b-form-select
              ></b-col>
            </b-row>
          </td>
        </tr>

        <!-- Select Second App Involved -->
        <tr>
          <td class="plan-details-key" scope="col">
            <span>Select Second Application</span>
          </td>
          <td class="plan-details-value" scope="col">
            <b-row>
              <b-col>
                <b-form-select
                  @change="appChanged"
                  v-model="selectedActionApp"
                  :options="actionsApps"
                  class="select-el"
                ></b-form-select>
              </b-col>
            </b-row>
          </td>
        </tr>

        <tr v-for="(item, key) in planDetails[this.selectedPlan]" :key="key">
          <td class="plan-details-key">
            <span>{{ item[0] }}</span>
          </td>

          <td class="plan-details-value">
            <span class="order-subtotal-amount">{{ item[1] }}</span>
          </td>
        </tr>
        <tr>
          <td scope="col" class="plan-details-key">
            <span>Billing Cycle</span>
          </td>
          <td scope="col" class="plan-details-value">
            <b-form-select
              v-model="billingCycleSelected"
              :options="billingCycle"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>

        <tr>
          <td scope="col" class="plan-details-key">
            <span>Currency</span>
          </td>
          <td scope="col" class="plan-details-value">
            <b-form-select
              v-model="currencySelected"
              :options="currencies"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>

        <tr v-for="(item, key) in priceDetail[this.selectedPlan]" :key="key">
          <td
            v-if="billingCycleSelected == 'Monthly'"
            scope="col"
            class="plan-details-key"
          >
            <span>Price</span>
          </td>
          <td
            v-if="billingCycleSelected == 'Monthly'"
            scope="col"
            class="plan-details-value"
          >
            <span>{{ item[currencySelected][0] }}</span>
          </td>
          <td
            v-if="billingCycleSelected == 'Annually'"
            scope="col"
            class="plan-details-key"
          >
            <span>Price</span>
          </td>
          <td
            v-if="billingCycleSelected == 'Annually'"
            scope="col"
            class="plan-details-value"
          >
            <span>{{ item[currencySelected][1] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { fetchSupportedApps } from './helpers/fetchSupportedApps'
import {
  fetchAllPackages,
  extractWorkflows,
  extractDescription,
  extractActionApps,
} from './helpers/worklfowSelector'

export default {
  name: 'PlanInformationMarketplace',
  data() {
    return {
      supportedApps: [{ text: 'one', value: '1' }],
      actionsApps: [{ text: 'one', value: '1' }],
      workflowList: [],
      selectedTriggerApp: `hubspot`,
      selectedActionApp: `microsoftBC`,
      selectedPlan: 'Starter',
      billingCycleSelected: 'Monthly',
      billingCycle: ['Monthly', 'Annually'],
      currencySelected: 'DKK',
      currencies: ['DKK', 'USD', 'EUR'],
      selectedTrigger: 'Deal Won',
      selectedAction: 'Create Invoice',
      cmsPackageID: 1,
      workflow: 'Automatic invoicing',
      workflowDiscrption:
        'Automatically create an invoice on e-conomic without manually having to create customer and product information, when a new deal is won on HubSpot.',
      currency: 'DKK',
      plans: ['Starter', 'Business', 'Business Premium', 'Enterprise'],
      planDetails: {
        Starter: {
          tasks: ['Transactions', 250],
        },
        Business: {
          tasks: ['Transactions', 500],
        },
        'Business Premium': {
          tasks: ['Transactions', 1000],
        },
        Enterprise: {
          tasks: ['Transactions', 4000],
        },
      },
      priceDetail: {
        Starter: {
          price: {
            USD: ['$28.8', '$288'],
            DKK: ['Kr. 179', 'Kr. 1788'],
            EUR: ['€24', '€240'],
          },
        },
        Business: {
          price: {
            USD: ['$48.1', '$480'],
            DKK: ['Kr. 299', 'Kr. 2988'],
            EUR: ['€40.8', '€408'],
          },
        },
        'Business Premium': {
          price: {
            USD: ['$77.1', '$768'],
            DKK: ['Kr. 479', 'Kr. 4788'],
            EUR: ['€64.8', '€648'],
          },
        },
        Enterprise: {
          price: {
            USD: ['$142.8', '$1428'],
            DKK: ['Kr. 839', 'Kr. 8388'],
            EUR: ['€111.6', '€1116'],
          },
        },
      },
    }
  },
  methods: {
    triggerAppChanged() {
      this.actionsApps = extractActionApps(this.selectedTriggerApp)
      this.selectedActionApp = this.actionsApps[0].value
      this.appChanged()
    },
    appChanged() {
      this.workflowList = extractWorkflows(
        this.selectedTriggerApp,
        this.selectedActionApp
      )
      this.workflow = this.workflowList[0].value
      this.cmsPackageID = this.workflow
      this.workflowDiscrption = extractDescription(this.workflow)
    },
    workflowDiscriptionUpdater() {
      this.workflowDiscrption = extractDescription(this.workflow)
    },
  },
  async created() {
    let urlParams = new URLSearchParams(window.location.search)
    //fetch apps from cms
    this.supportedApps = await fetchSupportedApps(this.$rollbar)
    this.actionsApps = this.supportedApps
    await fetchAllPackages(this.$rollbar)

    if ('marketplace' === urlParams.get('type')) {
      this.selectedPlan = urlParams.get('selectedPlan')
      this.currencySelected = urlParams.get('currencySelected')
      this.billingCycleSelected = urlParams.get('billingCycleSelected')
      this.currencySelected = urlParams.get('currencySelected')
    }
    // fetch package from cms and populate details
    try {
      const cmsPackageID = this.$route.query.id
      const { data: workflowData } = await axios.get(
        `https://cms.cloudify.biz/marketplace-packages/${cmsPackageID}`
      )

      this.$rollbar.info(`Package Fetched From CMS With ID: ${cmsPackageID}`, {
        data: workflowData,
      })

      this.selectedActionApp = workflowData.destination.slug
      this.selectedTriggerApp = workflowData.source.slug
      this.workflowList = extractWorkflows(
        this.selectedTriggerApp,
        this.selectedActionApp
      )
      this.actionsApps = extractActionApps(this.selectedTriggerApp)
      this.workflow = workflowData.id
      this.workflowDiscrption = extractDescription(this.workflow)
      this.selectedTrigger = workflowData.trigger
      this.selectedAction = workflowData.action
      this.cmsPackageID = cmsPackageID
    } catch (error) {
      this.$rollbar.error('Error While Fetching Package From CMS', { error })
      // eslint-disable-next-line no-console
      console.log(error, error.response.status)
      if (error.response.status === 404 || error.response.status === 500) {
        this.$router.push('/package-not-found')
      }
    }
  },
}
</script>

<style scoped>
.plan-details-key {
  vertical-align: middle;
  border: 1px solid #d6d6d6;
}
.plan-details-key span {
  color: #051555;
  font-weight: 600 !important;
  font-size: 14px;
}
.plan-details-value {
  border: 1px solid #d6d6d6;
}
.plan-details-value span {
  color: #627792;
  font-weight: 400;
  font-size: 14px;
}
.table-bg {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  margin-top: 30px;
}
.select-el {
  min-height: 45px !important;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 14px !important;
  color: #627792;
}
</style>
