<template>
  <div>
    <!-- Start Checkout Area -->
    <section class="checkout-area ptb-80">
      <div class="container">
        <!-- Returning Customer Link -->
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="user-actions border-strip">
              <i data-feather="edit"></i>
              <p class="top-card-text">
                <span class="top-card-span">Returning customer?</span> Click
                <a
                  href="https://app.cloudify.biz/login?returnUrl=https%3A%2F%2Fapp.cloudify.biz"
                >
                  here
                </a>
                to login
              </p>
            </div>
          </div>
        </div>
        <p v-if="test" class="test-style">
          *You will be creating a test account
        </p>
        <form>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="billing-details register-form-cont">
                <h3 class="heading">Registration Form</h3>
                <RegistrationForm ref="__RegistrationForm"></RegistrationForm>
                <h3 class="heading my-4">Billing Details</h3>
                <BillingDetails ref="__BillingDetails"></BillingDetails>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="order-details register-form-cont">
                <h3 class="heading">Your Plan Information</h3>
                <PlanInformationMarketplace
                  ref="__PlanInformationMarketplace"
                  v-if="typeOfService === 'marketplace'"
                ></PlanInformationMarketplace>
                <PlanInformationLighthouse
                  ref="__PlanInformationLighthouse"
                  v-if="typeOfService === 'lighthouse'"
                ></PlanInformationLighthouse>
                <div class="pt-4">
                  <p>
                    <input
                      class="aggreement"
                      v-model="tnc"
                      type="checkbox"
                      id="tnc"
                      name="radio-group"
                      value="true"
                    />
                    <label for="tnc" class="aggreement-label-1"
                      >I accept the
                      <a
                        href="https://www.cloudify.biz/terms-and-conditions"
                        target="_blank"
                        class="agreement-label"
                        >Terms and Conditions</a
                      ></label
                    >
                  </p>
                  <p>
                    <input
                      class="aggreement"
                      v-model="dpa"
                      type="checkbox"
                      id="dpa"
                      name="radio-group2"
                      value="true"
                    />
                    <label for="dpa" class="aggreement-label-1"
                      >I accept the
                      <a
                        href="https://marketplace.cloudify.biz/data-process-agreement"
                        target="_blank"
                        class="agreement-label"
                        >Data Process Agreement</a
                      ></label
                    >
                  </p>
                </div>
                <a
                  href="javascript:void(0)"
                  @click="prepareAccount()"
                  class="btn order-btn button"
                  ><div v-if="loading == false">{{ submitButtonText }}</div>
                  <div v-else>
                    <b-spinner
                      variant="light"
                      label="Spinning"
                    ></b-spinner></div
                ></a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    <!-- End Checkout Area -->
  </div>
</template>

<script>
import { auth, db as firestore } from '../../../firebase'
import RegistrationForm from './RegistrationForm.vue'
import BillingDetails from './BillingDetails.vue'
import PlanInformationMarketplace from './planInformationMarketplace.vue'
import PlanInformationLighthouse from './planInfromationLighthouse.vue'
import { formValidator } from './helpers/formValidator'
import {
  potentialUserMarketplace,
  potentialUserLighthouse,
} from './helpers/firebaseAccountCreation'
import axios from 'axios'

export default {
  name: 'Register',
  components: {
    RegistrationForm,
    BillingDetails,
    PlanInformationMarketplace,
    PlanInformationLighthouse,
  },
  props: ['typeOfService'],
  data() {
    return {
      loading: false,
      tnc: false,
      dpa: false,
      submitButtonText: 'Create Account',
      test: false,
    }
  },
  methods: {
    prepareAccount() {
      let potentialFirebaseUserDetails = {}
      const registrationFormFields =
        this.$refs.__RegistrationForm.registrationForm
      const {
        fullName,
        vat,
        company,
        email,
        phone,
        password,
        repeatPassword,
        country,
      } = registrationFormFields

      const billingDetailsFields = this.$refs.__BillingDetails.billingForm
      const { address, city, state, zip, notes } = billingDetailsFields

      if (
        this.typeOfService === 'marketplace' &&
        formValidator({
          fullName,
          vat,
          company,
          email,
          phone,
          password,
          repeatPassword,
          country,
          address,
          city,
          state,
          zip,
          notes,
        }) &&
        this.dpa === true &&
        this.tnc === true
      ) {
        this.loading = true
        const marketplaceFields = this.$refs.__PlanInformationMarketplace
        let {
          selectedPlan,
          currencySelected,
          selectedTriggerApp,
          selectedActionApp,
          selectedTrigger,
          selectedAction,
          billingCycleSelected,
          workflow,
          workflowDiscrption,
          cmsPackageID,
        } = marketplaceFields
        const numberOfTransactions =
          marketplaceFields.planDetails[selectedPlan].tasks[1]

        potentialFirebaseUserDetails = potentialUserMarketplace(
          {
            fullName,
            vat,
            company,
            email,
            phone,
            password,
            address,
            city,
            state,
            zip,
            notes,
            repeatPassword,
            country,
            selectedPlan,
            cmsPackageID,
            workflow,
            workflowDiscrption,
            selectedTriggerApp,
            selectedActionApp,
            selectedTrigger,
            selectedAction,
            numberOfTransactions,
            billingCycleSelected,
            currencySelected,
          },
          this.test
        )
      } else if (
        this.typeOfService === 'lighthouse' &&
        formValidator({
          fullName,
          vat,
          company,
          email,
          phone,
          password,
          repeatPassword,
          country,
          address,
          city,
          state,
          zip,
          notes,
        }) &&
        this.dpa === true &&
        this.tnc === true
      ) {
        this.loading = true
        const lighthouseFields = this.$refs.__PlanInformationLighthouse
        const {
          selectedPlan,
          currencySelected,
          costSelected: billingCycle,
        } = lighthouseFields
        const price =
          lighthouseFields.priceDetail[selectedPlan][currencySelected][
            billingCycle === 'Monthly' ? 0 : 1
          ]

        potentialFirebaseUserDetails = potentialUserLighthouse(
          {
            fullName,
            vat,
            company,
            email,
            phone,
            password,
            repeatPassword,
            country,
            address,
            city,
            state,
            zip,
            notes,
            selectedPlan,
            currencySelected,
            billingCycle,
            price,
          },
          this.test
        )
      } else {
        this.loading = false
        this.$toasted.show('Form Invalid', {
          icons: 'shopping_cart',
          theme: 'bubble',
          position: 'top-right',
          duration: 3000,
        })
        return
      }
      this.createAccount(email, password, potentialFirebaseUserDetails)
    },

    async createAccount(email, password, data) {
      try {
        const firebaseAccountCreation =
          await auth.createUserWithEmailAndPassword(email, password)
        data.user.database = firebaseAccountCreation.user.uid
        data.owner = data.user.database
        await firestore.collection('cloudify').doc().set(data)
        await this.sendDetails(data)
        this.$rollbar.info('Firebase Account Created Successfully', { data })
        this.$toasted.show(
          'Your account is created successfully. You will be redirected to Login Page',
          {
            icons: 'shopping_cart',
            theme: 'bubble',
            type: 'success',
            position: 'top-right',
            duration: 2000,
          }
        )
        setTimeout(() => {
          window.location.href =
            'https://app.cloudify.biz/login?returnUrl=https%3A%2F%2Fapp.cloudify.biz'
        }, 2000)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$toasted.show(error.message, {
          icons: 'shopping_cart',
          theme: 'bubble',
          position: 'top-right',
          duration: 3000,
        })
      }
    },

    async sendDetails(details) {
      if (this.typeOfService === 'marketplace') {
        try {
          await axios.post(
            this.test
              ? 'https://dev--marketplace-onboarding.cloudify-clients.autocode.gg/a1Flow/'
              : 'https://marketplace-onboarding.cloudify-clients.autocode.gg/a1Flow/',
            details
          )
          this.$rollbar.info('A1Flow Executed Successfully.', {
            data: details,
          })
        } catch (error) {
          this.$rollbar.error('A1Flow Error Occurred', {
            error,
            data: { details },
          })
        }
      } else if (this.typeOfService === 'lighthouse') {
        try {
          await axios.post(
            this.test
              ? 'https://dev--marketplace-onboarding.cloudify-clients.autocode.gg/standalone/'
              : 'https://marketplace-onboarding.cloudify-clients.autocode.gg/standalone/',
            details
          )
          this.$rollbar.info('Standalone Flow Executed Successfully.', {
            data: details,
          })
        } catch (error) {
          this.$rollbar.error('Standalone Flow Error Occurred', {
            error,
            data: { details },
          })
        }
      }
    },
  },
  computed: {},
  created() {
    if (this.$route.query.test == 'true') {
      this.test = true
    } else {
      this.test = false
    }
  },
}
</script>
<style scoped>
.aggreement {
  margin-right: 1em;
}

.button {
  background-color: #0057ff;
  color: #ffffff;
  font-size: 14px;
  font: 14px Poppins, sans-serif;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
}
.test-style {
  color: red;
}
.border-strip {
  border-top: 5px solid #0057ff;
  margin-top: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.top-card-text {
  color: #627792 !important;
  font-weight: 400;
  font-size: 14px !important;
}
.top-card-span {
  font-weight: 600;
  color: #627792;
}
.top-card-text a {
  color: #0057ff;
}
.register-form-cont {
  background-color: #fafafa;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: none;
}
.heading {
  color: #051555;
  font-weight: 500;
  font-size: 20px;
}
.aggreement-label-1 {
  color: #627792;
  font-weight: 400;
}
.agreement-label {
  color: #0057ff;
}
@media screen and (max-width: 992px) {
  .register-form-cont {
    margin-top: 20px;
  }
}
</style>
