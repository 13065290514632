<template>
  <div class="row my-4">
    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label> Country </label>
        <div class="select-box">
          <!-- <select
            @change="eraseSelectedCountry()"
            v-model="registrationForm.country"
            class="form-control"
          >
            <option v-for="(option, key) in countries" :key="key">
              {{ option }}
            </option>
          </select> -->
          <b-form-select
            @change="eraseSelectedCountry()"
            v-model="registrationForm.country"
            :options="countries"
            class="form-select-el"
          ></b-form-select>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label>Company Name*</label>
        <input
          v-model="registrationForm.company"
          type="text"
          class="form-control"
          @blur="validationCompany"
        />
        <P v-if="validationFeilds.validateCompany === 'invalid'" class="error"
          >Please Enter Your Company Name</P
        >
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label>Full Name*</label>
        <input
          v-model="registrationForm.fullName"
          type="text"
          class="form-control"
          @blur="validation"
        />
        <p v-if="validationFeilds.validateName === 'invalid'" class="error">
          Please Enter Your Name
        </p>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-12">
      <div class="form-group">
        <label>VAT Number*</label>
        <div class="row">
          <div class="col-lg-9 col-md-10 col-8">
            <input
              placeholder="Please Enter VAT Code"
              v-model="registrationForm.vat"
              type="text"
              class="form-control"
              @blur="validationVat"
            />
          </div>
          <div v-if="checkIfCountryEU()" class="col-lg-3 col-md-2 col-2">
            <button
              class="pt-2 pb-2 btn button"
              type="button"
              @click.prevent="searchVatDetails"
              id="btn"
            >
              <span class="px-3 py-4">Search</span>
            </button>
          </div>
        </div>
        <p v-if="validationFeilds.validateVat === 'invalid'" class="error">
          Please Enter VAT Code
        </p>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-6">
      <div class="form-group">
        <label>Email Address*</label>
        <input
          v-model="registrationForm.email"
          type="email"
          class="form-control"
          @blur="validationEmail"
        />
        <p v-if="validationFeilds.validateEmail === 'invalid'" class="error">
          Invalid Email
        </p>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-6">
      <div class="form-group">
        <label>Phone</label>
        <input
          v-model="registrationForm.phone"
          type="text"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-6">
      <div class="form-group">
        <label>Password*</label>
        <input
          v-model="registrationForm.password"
          type="password"
          class="form-control"
          @blur="validationPassword"
        />
        <p v-if="validationFeilds.validatePassword === 'invalid'" class="error">
          Please Enter the Password
        </p>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-6">
      <div class="form-group">
        <label>Repeat Password*</label>
        <input
          v-model="registrationForm.repeatPassword"
          type="password"
          class="form-control"
          @blur="validationRepeatPassword"
        />
        <p
          v-if="validationFeilds.validateRepeatPassword === 'invalid'"
          class="error"
        >
          Please Repeat Your Password
        </p>
        <p v-if="validationFeilds.passwordMatch === 'invalid'" class="error">
          Password Doesn't Match
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { countries } from './constants/countries'
import { euCountries } from './constants/euCountries'
import { validateEmail } from './helpers/emailValidator'
import { prefixCountryCode } from './helpers/prefixCountryCode'
import { EventBus } from '../../../main'
import axios from 'axios'

export default {
  name: 'RegistrationForm',

  data() {
    return {
      countries: countries,
      registrationForm: {
        fullName: '',
        vat: '',
        company: '',
        email: '',
        phone: '',
        password: '',
        repeatPassword: '',
        country: 'Denmark',
      },
      validationFeilds: {
        validateName: 'pending',
        validateVat: 'pending',
        validateEmail: 'pending',
        validatePassword: 'pending',
        validateRepeatPassword: 'pending',
        validateCompany: 'pending',
        passwordMatch: 'pending',
      },
    }
  },
  methods: {
    eraseSelectedCountry() {
      this.registrationForm.vat = ''
    },

    async searchVatDetails() {
      this.$toasted.show('Trying To Find Details.', {
        icons: 'shopping_cart',
        theme: 'bubble',
        type: 'success',
        position: 'top-right',
        duration: 1000,
      })
      if (
        this.registrationForm.vat &&
        euCountries.includes(this.registrationForm.country)
      ) {
        //prefix country code first
        this.registrationForm.vat = prefixCountryCode(
          this.registrationForm.vat,
          this.registrationForm.country
        )
        try {
          const vatValidationRes = await axios.post(
            'https://cloudify-clients.api.stdlib.com/marketplace-onboarding/vatValidation/',
            { vatNumberIncludingCode: this.registrationForm.vat }
          )

          if (vatValidationRes.data.valid) {
            this.$toasted.show('Details Found.', {
              icons: 'shopping_cart',
              theme: 'bubble',
              type: 'success',
              position: 'top-right',
              duration: 2000,
            })
            this.validationFeilds.validateCompany = 'valid'
            this.registrationForm.company = vatValidationRes.data.name
            EventBus.$emit('fetchedDetails', vatValidationRes.data)
          } else {
            this.$toasted.show('Not A Valid VAT', {
              icons: 'ban',
              theme: 'bubble',
              position: 'top-right',
              duration: 2000,
            })
            this.registrationForm.company = ''
            this.validationFeilds.validateCompany = 'valid'
            EventBus.$emit('invalidVat')
          }
          this.$rollbar.info('VAT Details Fetched', {
            data: { vatValidationRes },
          })
        } catch (error) {
          this.$rollbar.error('Error Fetching VAT Details', {
            data: { 'VAT Number': this.registrationForm.vat },
            error,
          })
          this.$toasted.show(`Error: ${error.response.data.msg}`, {
            icons: 'ban',
            theme: 'bubble',
            position: 'top-right',
            duration: 3000,
          })
        }
      }
    },
    checkIfCountryEU() {
      if (euCountries.includes(this.registrationForm.country)) return true
      else return false
    },
    validation() {
      if (this.registrationForm.fullName === '') {
        this.validationFeilds.validateName = 'invalid'
      } else {
        this.validationFeilds.validateName = 'valid'
      }
    },
    validationVat() {
      if (this.registrationForm.vat === '') {
        this.validationFeilds.validateVat = 'invalid'
      } else {
        this.validationFeilds.validateVat = 'valid'
      }
    },
    validationEmail() {
      if (
        this.registrationForm.email === '' ||
        !validateEmail(this.registrationForm.email)
      ) {
        this.validationFeilds.validateEmail = 'invalid'
      } else {
        this.validationFeilds.validateEmail = 'valid'
      }
    },
    validationPassword() {
      if (this.registrationForm.password === '') {
        this.validationFeilds.validatePassword = 'invalid'
      } else {
        this.validationFeilds.validatePassword = 'valid'
      }
    },
    validationCompany() {
      if (this.registrationForm.company === '') {
        this.validationFeilds.validateCompany = 'invalid'
      } else {
        this.validationFeilds.validateCompany = 'valid'
      }
    },
    validationRepeatPassword() {
      if (this.registrationForm.repeatPassword === '') {
        this.validationFeilds.validateRepeatPassword = 'invalid'
        this.validationFeilds.passwordMatch = 'valid'
      } else {
        this.validationFeilds.validateRepeatPassword = 'valid'
        if (
          this.registrationForm.password !==
          this.registrationForm.repeatPassword
        ) {
          this.validationFeilds.passwordMatch = 'invalid'
        } else {
          this.validationFeilds.passwordMatch = 'valid'
        }
      }
    },
  },
}
</script>
<style scoped>
.error {
  color: #ed5f55;
  font-size: 12px;
}
.form-group label {
  font-weight: 600;
  font-size: 14px;
  color: #051555;
}
.form-group input {
  border: 1px solid #d6d6d6;
  background-color: #ffffff !important;
  border-radius: 3px;
  min-height: 45px;
  color: #627792;
  font-size: 14px;
}
.form-select-el {
  border: 1px solid #d6d6d6;
  background-color: #ffffff !important;
  border-radius: 3px;
  min-height: 45px;
  color: #627792;
  font-size: 14px;
}
.button {
  background-color: #0057ff;
  border-radius: 3px;
  text-transform: none;
  max-width: 116px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
</style>
