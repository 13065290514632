import {validateEmail} from '../helpers/emailValidator'

export const formValidator = function (params) {
    try {
        const {fullName, company, vat, email, password, repeatPassword, address, city, zip} = params

        if(fullName.length === 0 || company.length === 0 || vat.length === 0 || password !== repeatPassword || address.length === 0 || city.length === 0 || !validateEmail(email) || zip.length ===0) return false
        else return true  
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
    }
}