<template>
  <div class="main-container-bg">
    <div class="features-line cont1"></div>
    <div class="features-line cont2"></div>
    <div class="features-contact-bg">
      <div
        class="container d-flex flex-column justify-content-center align-items-center"
      >
        <div class="content-container">
          <h2>Leave all the heavy lifting to Lighthouse</h2>
          <p>
            Lighthouse is an open, customizable, future-ready, and all-in-one
            platform – unlike any other platform. We give you that competitive
            edge to stay ahead of others.
          </p>
          <a href="/support" class="btn button">Connect with us to know more</a>
        </div>
        <hr class="horizontal-line" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactSection',
}
</script>

<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
  text-align: center;
}
.content-container h2 {
  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
}
.content-container p {
  color: #dee3eb;
  font-weight: 400;
  font-size: 15px;
}
.button {
  color: #ffffff;
  background-color: #0057ff;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  text-transform: none;
  margin-top: 20px;
  max-width: 350px;
}
.features-contact-bg {
  background-color: #0e001f;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.main-container-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features-line {
  height: 14px;
  background-color: #0e001f;
  width: 100%;
}
.cont1 {
  opacity: 0.2;
}
.cont2 {
  opacity: 0.5;
}
.horizontal-line {
  border: 0.5px solid #ffffff;
  opacity: 0.1;
  width: 100%;
  margin-top: 80px;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .content-container h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .content-container h2 {
    font-size: 27px;
  }
}
</style>
