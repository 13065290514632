import { euCountries } from '../constants/euCountries'

export const potentialUserMarketplace = function (data, test) {
  const {
    fullName,
    vat,
    company,
    email,
    phone,
    country,
    address,
    city,
    state,
    zip,
    notes,
    selectedPlan,
    cmsPackageID,
    selectedTriggerApp,
    selectedActionApp,
    numberOfTransactions,
    billingCycleSelected,
    currencySelected,
  } = data

  const tempBody = {
    credentials: {
      [`${selectedTriggerApp}`]: {},
      [`${selectedActionApp}`]: {},
    },
    additionalPlanInformation: {
      numberOfTransactions: `${numberOfTransactions}`,
      billingCycleSelected: `${billingCycleSelected}`,
      currencySelected: `${currencySelected}`,
      cmsPackageID,
    },
    hubspotPipelineChangeFlag: false,
    user: {
      name: `${fullName}`,
      email: `${email}`,
      phone: `${phone}`,
      database: ``,
      company: `${company}`,
      planName: `${selectedPlan}`,
      address: {
        address: `${address}`,
        city: `${city}`,
        state: `${state}`,
        zip_code: `${zip}`,
        country: `${country}`,
      },
      tag: {
        type: 'Marketplace',
        variant: 'success',
        isTest: test == true ? true : false,
      },
      created: new Date(),
      logo: `${getImage()}`,
      notes: notes,
      additionalCompanyDetails: {
        vat: `${vat}`,
        taxExemption: `${checkTaxExemption(country)}`,
      },
    },
    sheets: {},
    notifications: {
      general: [
        {
          title: `${company} - Account`,
          textContent: 'New Account created successfully',
          time: String(new Date()),
        },
      ],
    },
  }

  return tempBody
}

export const potentialUserLighthouse = function (data, test) {
  const {
    fullName,
    vat,
    company,
    email,
    phone,
    country,
    address,
    city,
    state,
    zip,
    notes,
    selectedPlan,
    currencySelected,
    billingCycle,
    price,
  } = data

  const tempBody = {
    credentials: {},
    additionalPlanInformation: {
      currencySelected: `${currencySelected}`,
      billingCycleSelected: `${billingCycle}`,
      price: `${price}`,
    },

    user: {
      name: `${fullName}`,
      email: `${email}`,
      phone: `${phone}`,
      database: ``,
      company: `${company}`,
      planName: `${selectedPlan}`,
      address: {
        address: `${address}`,
        city: `${city}`,
        state: `${state}`,
        zip_code: `${zip}`,
        country: `${country}`,
      },
      tag: {
        type: 'Lighthouse',
        variant: 'success',
        isTest: test == true ? true : false,
      },
      created: new Date(),
      logo: `${getImage()}`,
      notes: notes,
      additionalCompanyDetails: {
        vat: `${vat}`,
        taxExemption: `${checkTaxExemption(country)}`,
      },
    },
    notifications: {
      general: [
        {
          title: `${company} - Account`,
          textContent: 'New Account created successfully',
          time: String(new Date()),
        },
      ],
    },
  }
  return tempBody
}

const checkTaxExemption = function (country) {
  if (country === 'Denmark') return 'none'
  else if (country !== 'Denmark' && euCountries.includes(country))
    return 'reverse'
  else return 'exempt'
}

export const getImage = function () {
  const number = Math.floor(Math.random() * 40) + 1
  const imageUrl = `https://firebasestorage.googleapis.com/v0/b/bpm-cloudify.appspot.com/o/userLogo%2Favatars%2F${number}.png?alt=media`
  return imageUrl
}
