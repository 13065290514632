<template>
  <div class="home-banner-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 image-content-cont">
          <div class="home-banner-content">
            <h1>
              Welcome to Lighthouse - Navigating Your Business Towards
              Effortless Digital Integration
            </h1>
            <p>
              Simplified Integration, Expertly Managed: Lighthouse offers a new
              paradigm in integration services. Here, we take complete charge of
              your integration needs. Our team meticulously handles every detail
              — from coding the business logic to setting up seamless
              connections between your applications. We manage the data flows,
              so you don't have to, offering a truly hands-off experience for
              our clients.
            </p>
            <!-- <a href="/signup" class="btn button">Start a Free Trial</a> -->
          </div>
        </div>

        <div class="col-lg-6">
          <div class="home-banner-image">
            <img
              src="../../../assets/img/home/lighthouse-homepage-graphics.svg"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
.home-banner-bg {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 150px;
  background-color: #0f0020;
}
.home-banner-content {
  max-width: 520px;
  position: relative;
  top: 70px;
}
.home-banner-content h1 {
  font-weight: 600;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 10px;
}
.home-banner-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #7592b9;
  opacity: 0.6;
}
.button {
  background-color: #02c65c;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}
.home-banner-image {
  position: relative;
  width: 100%;
  height: 100%;
  top: 50px;
}
@media screen and (max-width: 992px) {
  .image-content-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .home-banner-image {
    text-align: center;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-banner-content h1 {
    font-size: 42px;
  }
  .home-banner-content p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .home-banner-content h1 {
    font-size: 36px;
  }
}
</style>
