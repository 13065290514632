<template>
  <div style="margin-bottom: 100px">
    <div class="support-banner-bg">
      <div class="container">
        <div class="support-content-cont">
          <h1>How can we help you?</h1>
          <p>
            Lighthouse by Cloudify offers you the all-in-one platform for
            integrating multiple apps and customising automation. Contact us to
            know more.
          </p>
        </div>
      </div>
    </div>
    <div class="features-line cont1"></div>
    <div class="features-line cont2"></div>
  </div>
</template>

<script>
export default {
  name: 'BannerSection',
}
</script>

<style scoped>
.support-banner-bg {
  background-color: #0a0b36;
  position: relative;
  padding-top: 200px;
  padding-bottom: 150px;
}
.support-content-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.support-content-cont h1 {
  color: #ffffff;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
}
.support-content-cont p {
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  max-width: 520px;
  opacity: 0.75;
  color: #ffffff;
  text-align: center;
}
.features-line {
  height: 14px;
  background-color: #0a0b36;
}
.cont1 {
  opacity: 0.5;
}
.cont2 {
  opacity: 0.2;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .support-content-cont h1 {
    font-size: 42px;
  }
  .support-content-cont p {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .support-content-cont h1 {
    font-size: 36px;
  }
  .support-content-cont p {
    font-size: 16px;
  }
}
</style>
