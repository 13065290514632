import axios from "axios";

export async function fetchSupportedApps(rollbar) {
  try {
    const { data: fetchedPackages } = await axios.get(
      "https://cms.cloudify.biz/marketplace-packages?_limit=1000"
    );

    rollbar.info("All CMS Marketplace Packages Fetched", {
      data: fetchedPackages,
    });

    let appsCovered = [];
    let appsList = [];

    for (let i = 0; i < fetchedPackages.length; i++) {
      if (!appsCovered.includes(fetchedPackages[i].source.slug)) {
        appsList.push({
          text: fetchedPackages[i].source.name,
          value: fetchedPackages[i].source.slug,
        });
        appsCovered.push(fetchedPackages[i].source.slug);
      }
      if (!appsCovered.includes(fetchedPackages[i].destination.slug)) {
        appsList.push({
          text: fetchedPackages[i].destination.name,
          value: fetchedPackages[i].destination.slug,
        });
        appsCovered.push(fetchedPackages[i].destination.slug);
      }
    }
    return appsList;
  } catch (error) {
    rollbar.error("Error While Fetching All CMS Marketplace Packages", {
      error,
    });
    // eslint-disable-next-line no-console
    console.log(error);
  }
}
