<template>
  <div>
    <Banner></Banner>
    <Features></Features>
    <Workflows></Workflows>
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from '../landing-pages/zapier-page/Contact.vue'
import Banner from '../landing-pages/zapier-page/Banner.vue'
import Features from '../landing-pages/zapier-page/Features.vue'
import Workflows from '../landing-pages/zapier-page/Workflows.vue'
export default {
  name: 'Zapier',
  components: {
    Contact,
    Banner,
    Features,
    Workflows,
  },
}
</script>
