<template>
  <div class="home-features-container">
    <div class="container">
      <h2 class="home-features-heading">Why do you need Lighthouse?</h2>

      <!-- we show first six features -->
      <ul class="row unordered-list">
        <li
          v-for="item in newFeaturesData"
          :key="item.index"
          class="col-lg-4 col-md-6 col-sm-12 mb-4"
        >
          <div class="card-container">
            <img
              class="mb-2"
              :src="require(`@/assets/img/home/${item.image}`)"
              alt="image"
            />
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </li>
      </ul>
      <!-- we show all features -->
      <!-- <ul v-if="expand === true" class="row unordered-list">
        <li
          v-for="item in hiddenData"
          :key="item.index"
          class="col-lg-4 col-md-6 col-sm-12"
        >
          <div class="card-container">
            <img
              class="mb-2"
              :src="require(`@/assets/img/home/${item.image}`)"
              alt="image"
            />
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </li>
      </ul>

      <button class="btn button" @click="expandAllFeatures()">
        <span v-if="expand === false">see all features</span>
        <span v-else>show less</span>
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'LighthouseSection',
  data() {
    return {
      expand: false,
      featuresData: [
        {
          title: 'Modernized platform',
          description:
            'Lighthouse is your modernised and unified platform that adopts an agile integration strategy. It offers a seamless deployment that empowers your organisation.',
          image: 'icon-modernise-platforn.svg',
        },
        {
          title: 'Retrigger failed automation',
          description:
            'If an automation fails, you get a notification of the failed trigger and can easily retrigger the automation with no coding knowledge.',
          image: 'icon-retrigger.svg',
        },
        {
          title: 'End to end workflow platform',
          description:
            'Lighthouse is a unified platform that meets all your integration needs and makes your automation process seamless.',
          image: 'icon-end-to-end.svg',
        },
        {
          title: 'LH is also for people with no coding knowledge',
          description:
            'Yes you read it right! You do not need any coding knowledge for trying out our prebuilt workflows or for retriggering the failed automation.',
          image: 'icon-no-coding.svg',
        },
        {
          title: 'Explore the diverse apps gallery',
          description:
            'Connect multiple apps with ease. You can build custom workflows as per your business needs and select the preferable apps from our tech stack.',
          image: 'icon-explore-apps.svg',
        },
        {
          title: 'Get valuable workflow automation insights',
          description:
            'Understand the process flow with real-time dashboards and never miss an update again. Use graphs and charts to understand your processes better.',
          image: 'icon-get-valued-automation.svg',
        },
      ],
      hiddenData: [
        {
          title: 'High automation capabilities',
          description:
            'True automation solutions that are business specific, such as connecting multiple processes, automating it, and customising workflows.',
          image: 'icon-high-automation.svg',
        },
        {
          title: 'Managed pricing',
          description:
            'Fair and customizable pricing is what Cloudify believes in. Pay for the automation that you currently need for your business to grow.',
          image: 'icon-managed-pricing.svg',
        },
        {
          title: 'Automation support',
          description:
            'You can book a free consultation with our automation experts on how business automation can work for your business.',
          image: 'icon-support.svg',
        },
      ],
      newFeaturesData: [
        {
          title: 'Tailored Integration',
          description:
            "Crafted for Your Business: Every business is unique, and so are our integration solutions. At Lighthouse, we don't just offer a one-size-fits-all solution; we deliver custom-built integrations. Our skilled team designs and implements solutions precisely tailored to fit your specific business needs, requirements and processes.",
          image: 'icon-modernise-platforn.svg',
        },
        {
          title: 'Intuitive Monitoring Dashboard',
          description:
            "Experience unparalleled clarity with our monitoring dashboard. It's your window to the world of your integrations, where you can effortlessly oversee the health and performance of your integrations. View data flows in real-time, and take advantage of our unique replay feature to swiftly address and rectify any failed automations.",
          image: 'icon-get-valued-automation.svg',
        },
        {
          title: 'Reliability at Every Step',
          description:
            "We understand the importance of data integrity. That's why Lighthouse includes a real-time replay mechanism for failed automations, ensuring that every piece of your data reaches its destination correctly, even in the face of unforeseen interruptions.",
          image: 'icon-no-coding.svg',
        },
        {
          title: 'Seamless Service with Limited Need for Client Involvement',
          description:
            'Lighthouse is designed for businesses that prefer a comprehensive, managed service over the DIY approach. We take care of all the technical complexities in both development and operations, allowing you to focus on your core business activities without the need for in-depth integration expertise.',
          image: 'icon-support.svg',
        },
        {
          title: 'Focused on Security and Scalability',
          description:
            'Lighthouse is built with advanced security to protect your data, ensuring safe and reliable integrations. Designed for growth, our platform effortlessly scales with your business, adapting to your evolving needs and supporting your expansion every step of the way.',
          image: 'icon-high-automation.svg',
        },
        {
          title: 'Innovative Integration, Brought to You by Cloudify',
          description:
            'As the architects of Lighthouse, we at Cloudify harness our expertise to bring you a platform that embodies the essence of a lighthouse — guiding, illuminating, and protecting your digital integration journey.',
          image: 'icon-solution.svg',
        },
      ],
    }
  },
  methods: {
    expandAllFeatures() {
      this.expand = !this.expand
    },
  },
}
</script>

<style scoped>
.home-features-container {
  text-align: center;
  position: relative;
  margin-top: 100px;
}
.home-features-heading {
  color: #0f006b;
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 50px;
}
.card-container {
  background-color: #ffffff;
  border-radius: 5px;
  text-align: left;
  padding: 20px;
  margin-bottom: 30px;
  border: 2px solid rgba(239, 239, 239, 0.86);
  height: 100%;
  transition: transform 200ms;
}
.card-container:hover {
  border-bottom: 5px solid #0057ff;
  transform: translateY(-10px);
  box-shadow: 0px 4px 16px 0px #bfbfbf;
}
.card-container h3 {
  font-weight: 600;
  font-size: 16px;
  color: #051555;
  margin-top: 10px;
}
.card-container p {
  font-weight: 400;
  font-size: 14px;
  color: #627792;
}
.unordered-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.button {
  background-color: #0057ff;
  text-transform: none;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-features-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .home-features-heading {
    font-size: 27px;
  }
}
</style>
