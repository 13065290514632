<template>
  <div>
    <Banner></Banner>
    <Features></Features>
    <Workflows></Workflows>
    <Contact></Contact>
  </div>
</template>

<script>
import Banner from '../landing-pages/integromat/Banner.vue'
import Features from '../landing-pages/zapier-page/Features.vue'
import Workflows from '../landing-pages/zapier-page/Workflows.vue'
import Contact from '../landing-pages/integromat/Contact.vue'
export default {
  name: 'Make',
  components: {
    Banner,
    Features,
    Workflows,
    Contact,
  },
}
</script>
