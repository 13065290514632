export const countryCode = {
    "Andorra" : "AD",
    "Albania" : "AL",
    "Austria": "AT",
    "Åland Islands": "AX",
    "Bosnia and Herzegovina": "BA",
    "Belgium": "BE",
    "Bulgaria": "BG",
    "Belarus": "BY",
    "Switzerland": "CH",
    "Cyprus": "CY",
    "Czech Republic": "CZ",
    "Germany": "DE",
    "Denmark": "DK",
    "Estonia": "EE",
    "Spain": "ES",
    "Finland": "FI",
    "Faroe Islands" : "FO",
    "France": "FR",
    "United Kingdon": "GB",
    "Guernsey": "GG",
    "Greece": "GR",
    "Croatia": "HR",
    "Hungary": "HU",
    "Ireland": "IE",
    "Isle of Man": "IM",
    "Iceland": "IC",
    "Italy": "IT",
    "Jersey": "JE",
    "Liechtenstein": "LI",
    "Lithuania": "LT",
    "Luxembourg": "LU",
    "Latvia": "LV",
    "Monaco": "MC",
    "Moldova, Republic of": "MD",
    "Macedonia, The Former Yugoslav Republic of": "MK",
    "Malta": "MT",
    "Netherlands": "NL",
    "Norway": "NO",
    "Poland": "PL",
    "Portugal": "PT",
    "Romania": "RO",
    "Russian Federation": "RU",
    "Sweden": "SE",
    "Slovenia": "SI",
    "Svalbard and Jan Mayen": "SJ",
    "Slovakia": "SK",
    "San Marino": "SM",
    "Ukraine": "UA",
    "Holy See (Vatican City State)": "VA"
}