<template>
  <div class="worklows-main-container">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 image-container">
          <img
            src="../../../assets/img/zapier/img-get-faster-productivity.png"
            class="w-100"
            alt="image"
          />
        </div>
        <div class="col-md-6 col-sm-12 content-container">
          <h2 class="zapier-workflow-heading">
            Get faster productivity with our Workflows
          </h2>

          <ul class="unordered-list">
            <li
              v-for="item of workflowsData"
              :key="item.index"
              class="workflow-container"
            >
              <h3>{{ item.title }}</h3>
              <p>
                {{ item.description }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Workflows',
  data() {
    return {
      workflowsData: [
        {
          title: 'Prebuilt workflows',
          description:
            'We simply call it Do-It-Yourself workflows, which get instantly integrated into your workflow and simplify your automation goals.',
        },
        {
          title: 'Managed workflows',
          description:
            'Automating critical business processes can be a difficult task that involves the integration of multiple apps. Our automation expert team takes the pain in customizing the workflow that is just for your business.',
        },
      ],
    }
  },
}
</script>

<style scoped>
.worklows-main-container {
  margin-bottom: 130px;
}
.zapier-workflow-heading {
  font-weight: 600;
  font-size: 36px;
  color: #051555;
}
.workflow-container {
  border: 1px solid #dad9d9;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 30px;
  border-radius: 3px;
  transition: transform 200ms;
}
.workflow-container h3 {
  font-weight: 600;
  font-size: 18px;
  color: #051555;
}
.workflow-container p {
  font-weight: 400;
  font-size: 14px;
  color: #627792;
}
.workflow-container:hover {
  box-shadow: 0px 4px 16px 0px #bfbfbf;
}
.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.unordered-list {
  list-style-type: none;
  padding: 0px;
}
@media screen and (max-width: 992px) {
  .content-container {
    margin-top: 30px;
    text-align: center;
  }
  .workflow-container {
    text-align: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .zapier-workflow-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .zapier-workflow-heading {
    font-size: 27px;
  }
}
</style>
