import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import dotenv from 'dotenv'
dotenv.config()


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUjTlAIxMWsaoi5_vnDCLDqcd6qCPQFeU",
  authDomain: "bpm-cloudify.firebaseapp.com",
  databaseURL: (process.env.NODE_ENV === 'development' ? "http://localhost:9000/?ns=bpm-cloudify-default-rtdb" : "https://bpm-cloudify-default-rtdb.firebaseio.com"),
  projectId: "bpm-cloudify",
  storageBucket: "bpm-cloudify.appspot.com",
  messagingSenderId: "128624023223",
  appId: "1:128624023223:web:2e05098040a7277ae15f32",
  measurementId: "G-S5M5FWJGFJ"
};
// Initialize Firebase

// eslint-disable-next-line no-unused-vars
let app = null;
if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const DB = firebase.database();

if (process.env.NODE_ENV === 'development') {
  try {
    db.useEmulator("localhost", process.env.VUE_APP_FIRESTOREPORT);
    auth.useEmulator(`http://localhost:${process.env.VUE_APP_AUTHPORT}`);
    DB.useEmulator("localhost", process.env.VUE_APP_RTDBPORT);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err.message)
  }
}

export default firebase;
