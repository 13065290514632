import Vue from "vue";
import VueRouter from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import VueFeather from "vue-feather";
import VueCarousel from "vue-carousel";
import Toasted from "vue-toasted";
import App from "./App.vue";
import "./assets/style/custom.scss";
import { router } from "./router";
import store from "./store";
import Rollbar from "rollbar";

Vue.config.productionTip = false;

//config rollbar
Vue.prototype.$rollbar = new Rollbar({
  accessToken: "21de4326a92a4b46b679c2e5d8f695cf",
  captureUncaught: true,
  captureUnhandledRejections: true,
});

//it will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm) => {
  // eslint-disable-next-line no-console
  console.log(err);
  vm.$rollbar.error(err);
  throw err; // rethrow
};

Vue.use(BootstrapVue);
Vue.use(VueFeather);
Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(Toasted);

export const EventBus = new Vue();

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
