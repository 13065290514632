<template>
  <div class="form-cont">
    <div class="container">
      <div class="d-flex flex-row justify-content-center align-item-center">
        <div class="col-lg-7 col-md-12">
          <h2 class="support-form-heading mb-5">Get in touch with us</h2>
          <div id="hubspotForm" class="form-style"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Form',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5236568',
          formId: 'b96e8dbb-fbfe-4466-8e9b-a65afa0a417c',
          target: '#hubspotForm',
          cssClass: 'form-container',
        })
      }
    })
  },
}
</script>

<style scoped>
.support-form-heading {
  font-size: 36px;
  font-weight: 600;
  color: #0f006b;
  text-align: center;
}
.form-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-width: 480px; */
  margin-top: 30px;
}
.input-element {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dad9d9;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 64px;
  padding: 20px;
}
.form-cont {
  margin-bottom: 100px;
}
.button {
  color: #ffffff;
  font-size: 15px;
  background-color: #02c65c;
  font-weight: 600;
  text-transform: none;
  margin-top: 30px;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .support-form-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .support-form-heading {
    font-size: 27px;
  }
  .form-element {
    max-width: 480px;
  }
}
</style>
