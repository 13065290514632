<template>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label>Address*</label>
        <input
          v-model="billingForm.address"
          type="text"
          class="form-control"
          @blur="validationAddress"
        />
        <p v-if="validationFeilds.validateAddress === 'invalid'" class="error">
          Please Enter Your Address
        </p>
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <label>Town / City*</label>
        <input
          v-model="billingForm.city"
          type="text"
          class="form-control"
          @blur="validationCity"
        />
        <p v-if="validationFeilds.validateCity === 'invalid'" class="error">
          Please Enter Your City
        </p>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-6">
      <div class="form-group">
        <label>State / County </label>
        <input v-model="billingForm.state" type="text" class="form-control" />
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-6">
      <div class="form-group">
        <label>Postcode / Zip*</label>
        <input
          v-model="billingForm.zip"
          type="text"
          class="form-control"
          @blur="validationZip"
        />
        <p v-if="validationFeilds.validateZip === 'invalid'" class="error">
          Please Enter Your Zip Code
        </p>
      </div>
    </div>

    <div class="col-lg-12 col-md-12">
      <div class="form-group">
        <textarea
          v-model="billingForm.notes"
          name="notes"
          id="notes"
          cols="30"
          rows="4"
          placeholder="Order Notes"
          class="form-control"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../main'

export default {
  name: 'BillingDetails',
  data() {
    return {
      billingForm: {
        address: '',
        city: '',
        state: null,
        zip: '',
        notes: null,
      },
      validationFeilds: {
        validateAddress: 'pending',
        validateCity: 'pending',
        validateZip: 'pending',
      },
    }
  },
  methods: {
    validationAddress() {
      if (this.billingForm.address === '') {
        this.validationFeilds.validateAddress = 'invalid'
      } else {
        this.validationFeilds.validateAddress = 'valid'
      }
    },
    validationCity() {
      if (this.billingForm.city === '') {
        this.validationFeilds.validateCity = 'invalid'
      } else {
        this.validationFeilds.validateCity = 'valid'
      }
    },
    validationZip() {
      if (this.billingForm.zip === '') {
        this.validationFeilds.validateZip = 'invalid'
      } else {
        this.validationFeilds.validateZip = 'valid'
      }
    },
  },
  created() {
    EventBus.$on('fetchedDetails', (data) => {
      const { street, housenumber, city, state, postcode } = data.address
      this.billingForm.address = [street, housenumber].filter(Boolean).join(' ')
      this.billingForm.city = city
      ;(this.billingForm.state = state), (this.billingForm.zip = postcode)
      this.validationFeilds.validateAddress = 'valid'
      this.validationFeilds.validateCity = 'valid'
      this.validationFeilds.validateZip = 'valid'
    })

    EventBus.$on('invalidVat', () => {
      this.billingForm.address = ''
      this.billingForm.city = ''
      ;(this.billingForm.state = ''), (this.billingForm.zip = '')
      this.validationFeilds.validateAddress = 'valid'
      this.validationFeilds.validateCity = 'valid'
      this.validationFeilds.validateZip = 'valid'
    })
  },
}
</script>

<style scoped>
.error {
  color: #ed5f55;
  font-size: 12px;
}
.form-group label {
  font-weight: 600;
  font-size: 14px;
  color: #051555;
}
.form-group input {
  border: 1px solid #d6d6d6;
  background-color: #ffffff !important;
  border-radius: 3px;
  min-height: 45px;
  color: #627792;
  font-size: 14px;
}
.form-group textarea {
  border: 1px solid #d6d6d6;
  background-color: #ffffff !important;
  border-radius: 3px;
  color: #627792;
  font-size: 14px;
}
</style>
