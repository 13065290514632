<template>
  <div>
    <div class="bar-lines cont1"></div>
    <div class="bar-lines cont2"></div>
    <section class="zapier-contact-bg">
      <div
        class="container d-flex flex-column justify-content-center align-items-center"
      >
        <h2>Implement Zapier</h2>
        <p>
          The high-end functionality you want to add to your business is already
          there with us - whether it's pre-built workflows or custom/managed
          workflows, we do the heavy lifting for you. Get in touch with our
          automation experts to know more about Lighthouse by Cloudify and how
          you can Zap with it.
        </p>
        <a href="/support" class="btn button"> Contact Us </a>
        <hr class="horizontal-line" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<style scoped>
.zapier-contact-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #0e001f;
}

.zapier-contact-bg h2 {
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 600;
}

.zapier-contact-bg p {
  color: #dee3eb;
  font-weight: 400;
  font-size: 14px;
  max-width: 700px;
  text-align: center;
}

.button {
  background: #0057ff;
  border-radius: 3px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}
.bar-lines {
  height: 14px;
  background-color: #0e001f;
  width: 100%;
}
.cont1 {
  opacity: 0.2;
}
.cont2 {
  opacity: 0.5;
}
.horizontal-line {
  border: 0.5px solid #ffffff;
  opacity: 0.1;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .zapier-contact-bg h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .zapier-contact-bg h2 {
    font-size: 27px;
  }
}
</style>
