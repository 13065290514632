<template>
  <div class="order-table table-responsive table-bg">
    <table class="table table-bordered table-border">
      <thead>
        <!-- <tr>
          <th width="30%" scope="col" class="plan-details-key">
            <span>Plan Name</span>
          </th>
          <th scope="col">
            <b-form-select
              v-model="selectedPlan"
              :options="plans"
              class="select-el"
            ></b-form-select>
          </th>
        </tr> -->
      </thead>

      <tbody>
        <tr>
          <td width="30%" class="plan-details-key">
            <span>Plan Name</span>
          </td>

          <td class="plan-details-value">
            <b-form-select
              v-model="selectedPlan"
              :options="plans"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>
        <tr v-for="(item, key) in planDetails[this.selectedPlan]" :key="key">
          <td class="plan-details-key">
            <span>{{ item[0] }}</span>
          </td>

          <td class="plan-details-value">
            <span class="order-subtotal-amount">{{ item[1] }}</span>
          </td>
        </tr>
        <tr>
          <td scope="col" class="plan-details-key">
            <span>Billing Cycle</span>
          </td>
          <td scope="col" class="plan-details-value">
            <b-form-select
              v-model="costSelected"
              :options="planPrice"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>

        <tr>
          <td scope="col" class="plan-details-key">
            <span>Currency</span>
          </td>
          <td scope="col" class="plan-details-value">
            <b-form-select
              v-model="currencySelected"
              :options="currencies"
              class="select-el"
            ></b-form-select>
          </td>
        </tr>

        <tr>
          <!-- <tr v-for="(item, key) in priceDetail[this.selectedPlan]" :key="key"> -->
          <td
            v-if="costSelected == 'Monthly'"
            scope="col"
            class="plan-details-key"
          >
            <span>Price</span>
          </td>
          <td
            v-if="costSelected == 'Monthly'"
            scope="col"
            class="plan-details-value"
          >
            <span>{{ priceDetail[selectedPlan][currencySelected][0] }}</span>
          </td>
          <td
            v-if="costSelected == 'Annually'"
            scope="col"
            class="plan-details-key"
          >
            <span>Price</span>
          </td>
          <td
            v-if="costSelected == 'Annually'"
            scope="col"
            class="plan-details-value"
          >
            <span>{{ priceDetail[selectedPlan][currencySelected][1] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PlanInformationLighthouse',
  data() {
    return {
      costSelected: 'Monthly',
      currencySelected: 'DKK',
      planPrice: ['Monthly', 'Annually'],
      priceDetail: {
        Free: {
          DKK: ['Free', 'Free'],
          USD: ['Free', 'Free'],
          EUR: ['Free', 'Free'],
        },
        Lite: {
          DKK: ['Kr. 176', 'Kr. 1788'],
          USD: ['$28.8', '$288'],
          EUR: ['€ 25', '€ 240'],
        },
        Pro: {
          DKK: ['Kr. 379', 'Kr. 3588'],
          USD: ['$60', '$50'],
          EUR: ['€ 50', '€ 504'],
        },
        Expert: {
          DKK: ['Kr. 1129', 'Kr. 11148'],
          USD: ['$149', '$119'],
          EUR: ['€ 151', '€ 1512'],
        },
        Premium: {
          DKK: ['Kr. 2266', 'Kr. 22788'],
          USD: ['$299', '$249'],
          EUR: ['€ 304', '€ 3048'],
        },
      },
      currencies: ['DKK', 'USD', 'EUR'],
      plans: ['Free', 'Lite', 'Pro', 'Expert', 'Premium'],
      selectedPlan: 'Free',
      planDetails: {
        Free: {
          tasks: ['Transactions', 250],

          automations: ['Workflows', 1],
          subscription: ['Plan Selected', 'Free'],
        },
        Lite: {
          tasks: ['Transactions', 500],

          automations: ['Workflows', 2],
          subscription: ['Plan Selected', 'Lite'],
        },
        Business: {
          tasks: ['Transactions', 1000],

          automations: ['Workflows', 2],
          subscription: ['Plan Selected', 'Busniness'],
        },
        Pro: {
          tasks: ['Transactions', 4000],

          automations: ['Workflows', 3],
          subscription: ['Plan Selected', 'pro'],
        },
        Expert: {
          tasks: ['Transactions', 10000],

          automations: ['Workflows', '4+'],
          subscription: ['Plan Selected', 'Expert'],
        },
        Premium: {
          tasks: ['Transactions', 10000],

          automations: ['Workflows', '4+'],
          subscription: ['Plan Selected', 'Premium'],
        },
      },
    }
  },
}
</script>

<style scoped>
.plan-details-key {
  vertical-align: middle;
  border: 1px solid #d6d6d6;
}
.plan-details-key span {
  color: #051555;
  font-weight: 600 !important;
  font-size: 14px;
}
.plan-details-value {
  border: 1px solid #d6d6d6;
}
.plan-details-value span {
  color: #627792;
  font-weight: 400;
  font-size: 14px;
}
.table-bg {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  margin-top: 30px;
}
/* .table-border {
  border: 1px solid #d6d6d6 !important;
} */
.select-el {
  min-height: 45px !important;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 14px;
  color: #627792;
}
</style>
