<template>
  <div>
    <BannerSection></BannerSection>
    <!-- <ContactInfo></ContactInfo> -->
    <FormSection></FormSection>
    <ContactSection></ContactSection>
  </div>
</template>

<script>
import ContactSection from '../support/ContactSection.vue'
import BannerSection from './BannerSection.vue'
// import ContactInfo from './ContactInfo.vue'
import FormSection from './FormSection.vue'
export default {
  name: 'Support',
  components: {
    ContactSection,
    BannerSection,
    // ContactInfo,
    FormSection,
  },
}
</script>
