<template>
  <!-- Start Fun Facts Area -->
  <section class="funfacts-area ptb-80">
    <div class="container pad">
      <div class="section-title">
        <h2>Your Account Has Been Created Successfully</h2>
        <div class="bar"></div>
        <p>
          You need to verify your email address for logging into Lighthouse.
          <br />Please check your inbox.
        </p>
      </div>

      <div class="contact-cta-box">
        <h3>Lighthouse is your automation managing tool.</h3>
        <a
          href="https://app.cloudify.biz/login?returnUrl=https%3A%2F%2Fapp.cloudify.biz"
          class="btn btn-primary"
          >Lighthouse</a
        >
      </div>
      <div class="map-bg">
        <img src="../../../assets/img/map.png" alt="map" />
      </div>
    </div>
  </section>
  <!-- End Fun Facts Area -->
</template>

<script>
import 'odometer/themes/odometer-theme-default.css'

export default {
  name: 'NewAccount',
}
</script>

<style lang="scss" scoped>
.pad {
  margin-top: 5em;
  margin-bottom: 5em;
}
</style>
