<template>
  <!-- Start Fun Facts Area -->
  <section class="funfacts-area ptb-80">
    <div class="container pad">
      <div class="section-title">
        <h2>Something went wrong, requested package is not available</h2>
        <img src="../../../assets/img/not-found.png" alt="image" class="my-3" />
        <p>
          You might have selected a wrong package in Marketplace. <br />Please
          try again later.
        </p>
      </div>

      <div class="contact-cta-box">
        <h3>Go back to Marketplace to try again.</h3>
        <a href="https://marketplace.cloudify.biz/" class="btn btn-primary"
          >Marketplace</a
        >
      </div>
      <div class="map-bg">
        <img src="../../../assets/img/map.png" alt="map" />
      </div>
    </div>
  </section>
  <!-- End Fun Facts Area -->
</template>

<script>
import "odometer/themes/odometer-theme-default.css";

export default {
  name: "CMSPackageNotFound",
};
</script>

<style lang="scss" scoped>
.pad {
  margin-top: 5em;
  margin-bottom: 5em;
}
</style>
