<template>
  <div>
    <div class="container">
      <ul class="row unordered-list">
        <li
          v-for="item in featuresData"
          :key="item.index"
          class="col-lg-4 col-md-6 col-sm-12"
        >
          <div class="card-container">
            <img
              class="mb-2"
              :src="require(`@/assets/img/home/${item.image}`)"
              alt="image"
            />
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesSection',
  data() {
    return {
      featuresData: [
        {
          title: 'Lighthouse is the solution',
          image: 'icon-solution.svg',
          description:
            'Lighthouse is your modernised and unified platform that adopts an agile integration strategy. It acts as a conduit for communication between the systems, thus allowing integration and data syncing.',
        },
        {
          title: 'Retrigger failed automation',
          image: 'icon-retrigger.svg',
          description:
            'With every failed automation, a notification is sent to you and our team. You can simply retrigger the automation with no coding knowledge at all. If you are not able to do it, our experts will help you with this.',
        },
        {
          title: 'Manage end-to-end workflow',
          image: 'icon-end-to-end.svg',
          description:
            'Lighthouse is a unified platform that meets all your integration needs and makes your automation process seamless. You will have complete control over your integrations anywhere and anytime with real-time monitoring.',
        },
        {
          title: 'Silo busting data syncing',
          image: 'icon-silo-busting.svg',
          description:
            'Lighthouse disregards the need for engaging in huge manual data transfers. You can simply sync your business data in real-time from cloud and on-premise applications as and when updated.',
        },
        {
          title: 'DIY integrations',
          image: 'icon-diy-integrations.svg',
          description:
            'Lighthouse lets you create seamless integrations with an interactive interface that needs low coding, presents visual integrations, and has prebuilt workflows or connectors for its customers to choose from.',
        },
        {
          title: 'Exploring the diverse app gallery',
          image: 'icon-explore-apps.svg',
          description:
            'You can design custom workflows as per your business needs and select the preferable applications from our tech stack. This ensures an uninterrupted information flow within the connected applications.',
        },
        {
          title: 'Get valued automation insights',
          image: 'icon-get-valued-automation.svg',
          description:
            'The Lighthouse offers you an interactive dashboard with graphs and charts built on real-time data to understand your business processes better. What you get is a bird’s eye view of the complete workflow.',
        },
        {
          title: 'High automation capabilities',
          image: 'icon-high-automation.svg',
          description:
            'Lighthouse is your destination to true automation capabilities that are business-specific, where you can automate a part or whole of the business processes. The platform is intuitive as any other cloud application.',
        },
        {
          title: 'Managed pricing',
          image: 'icon-managed-pricing.svg',
          description:
            'Fair and customizable pricing is what Cloudify believes in. Pay for customised automation solutions that are specific to your business needs and size. We have scratched out multiple plans for you to choose from.',
        },
      ],
    }
  },
}
</script>

<style scoped>
.card-container {
  background-color: #ffffff;
  border-radius: 5px;
  text-align: left;
  padding: 20px;
  margin-bottom: 30px;
  border: 2px solid rgba(239, 239, 239, 0.86);
  min-height: 305px;
  transition: transform 200ms;
}
.card-container:hover {
  border-bottom: 5px solid #0057ff;
  transform: translateY(-10px);
  box-shadow: 0px 4px 16px 0px #bfbfbf;
}
.card-container h3 {
  font-weight: 600;
  font-size: 16px;
  color: #051555;
  margin-top: 10px;
}
.card-container p {
  font-weight: 400;
  font-size: 14px;
  color: #627792;
}
.unordered-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .card-container {
    min-height: 315px;
  }
}
</style>
