<template>
  <!-- Start Navbar Area -->
  <header id="header" :class="['headroom', { 'is-sticky': isSticky }]">
    <div class="startp-nav">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img
              v-if="isZapierPage && !isSticky"
              src="../../assets/img/lighthouse-logo-2022.svg"
              alt="logo"
            />
            <img v-else src="../../assets/img/lighthouse-logo-2022-black.svg" />
          </router-link>

          <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

          <b-collapse
            class="collapse navbar-collapse mean-menu"
            id="navbarSupportedContent"
            is-nav
          >
            <ul class="navbar-nav nav ml-auto">
              <li
                class="nav-item"
                v-for="item in headersData"
                :key="item.index"
              >
                <div v-if="item.title !== 'Partners'">
                  <router-link
                    :to="item.url"
                    :class="
                      'nav-link nav-font ' +
                      (isZapierPage && !isSticky ? 'nav-color' : '')
                    "
                  >
                    {{ item.title }}
                  </router-link>
                </div>

                <div v-else>
                  <router-link
                    :to="item.url"
                    :class="
                      'nav-link nav-font ' +
                      (isZapierPage && !isSticky ? 'nav-color' : '')
                    "
                    >{{ item.title }} <feather type="chevron-down"></feather
                  ></router-link>
                  <ul class="dropdown_menu">
                    <li
                      class="nav-item"
                      v-for="data in item.subtitle"
                      :key="data.index"
                    >
                      <router-link :to="data.link" class="nav-link nav-font">{{
                        data.title
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </b-collapse>

          <!-- <div v-if="currentPath !== '/new-account'" class="others-option">
            <router-link
              to="/signup"
              class="btn btn-light"
              :style="!isSticky && isZapierPage ? 'color: #ffffff' : ''"
            >
              <a>Sign Up</a>
            </router-link>
            <a
              href="https://app.cloudify.biz/login?returnUrl=https%3A%2F%2Fapp.cloudify.biz"
              class="btn button"
              >Login</a
            >
          </div> -->
        </nav>
      </div>
    </div>
  </header>
  <!-- End Navbar Area -->
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isSticky: false,
      currentPath: '',
      headersData: [
        {
          id: 1,
          title: 'Home',
          url: '/',
        },
        // {
        //   id: 2,
        //   title: 'Features',
        //   url: '/features',
        // },
        // {
        //   id: 3,
        //   title: 'Documents',
        //   url: '#',
        // },
        // {
        //   id: 4,
        //   title: 'Partners',
        //   icon: 'Feather-icon',
        //   url: '#',
        //   subtitle: [
        //     { title: 'Zapier', link: '/zapier' },
        //     { title: 'Make', link: '/make' },
        //   ],
        // },
        {
          id: 2,
          title: 'Contact',
          url: '/support',
        },
      ],
    }
  },

  mounted() {
    const that = this
    window.addEventListener('scroll', () => {
      let scrollPos = window.scrollY
      // eslint-disable-next-line no-console
      if (scrollPos >= 100) {
        that.isSticky = true
      } else {
        that.isSticky = false
      }
    })
  },

  computed: {
    shoppingCart() {
      return this.$store.state.cart
    },
    // This function is used to change the LH logo and text color present in navbar while scrolling in new pages
    isZapierPage() {
      return (
        this.$route.path === '/zapier' ||
        this.$route.path === '/make' ||
        this.$route.path === '/' ||
        this.$route.path === '/features' ||
        this.$route.path === '/support'
      )
    },
  },
  created() {
    this.currentPath = this.$router.currentRoute.path
  },
}
</script>

<style>
.startp-nav .navbar-toggler {
  background-color: #ffffff;
}
.nav-font {
  font-weight: 600 !important;
}
.active {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  color: #0088ec !important;
  text-underline-offset: 10px;
}
.button {
  background-color: #0057ff !important;
  color: #ffffff !important;
}
@media screen and (min-width: 767px) {
  .nav-color {
    color: #ffffff !important;
  }
  .nav-color:hover {
    color: #0088ec !important;
  }
}
</style>
