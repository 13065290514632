<template>
  <div class="home-contact-section">
    <div class="bar-lines cont1"></div>
    <div class="bar-lines cont2"></div>
    <div class="home-contact-bg">
      <div class="container home-contact-content">
        <h2>Turn Data into Action – The Need of the Hour</h2>
        <p>
          In today’s fast-paced business world, transforming data into
          actionable insights is more crucial than ever. Connect with our expert
          team at Lighthouse by Cloudify to explore how our integration
          solutions can empower your business. Reach out to us today to discover
          the full potential of Lighthouse and take the first step towards
          data-driven success.
        </p>
        <div class="buttons-cont">
          <a href="/support" class="btn button mt-2">Contact Us</a>
          <!-- <a href="/zapier" class="btn button1 mt-2">DIY Workflow</a> -->
        </div>
      </div>
      <img
        src="../../../assets/img/home/img-footer-app-flows.svg"
        alt="image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
}
</script>

<style scoped>
.home-contact-bg {
  background-color: #0e001f;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.home-contact-section {
  margin-top: 110px;
}
.home-contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  padding-top: 50px;
}
.home-contact-content h2 {
  color: #ffffff;
  font-weight: 600;
  font-size: 36px;
  max-width: 520px;
}
.home-contact-content p {
  color: #dee3eb;
  font-size: 14px;
  font-weight: 400;
  width: 75%;
}
.button {
  color: #ffffff;
  font-size: 14px;
  background-color: #0057ff;
  border-radius: 5px;
  font-weight: 600;
  text-transform: none;
  width: 180px;
  margin-right: 20px;
}
.button1 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  background-color: #02c65c;
  border-radius: 5px;
  text-transform: none;
  width: 180px;
}
.bar-lines {
  height: 14px;
  background-color: #0e001f;
  width: 100%;
}
.cont1 {
  opacity: 0.2;
}
.cont2 {
  opacity: 0.5;
}
@media screen and (max-width: 400px) {
  .buttons-cont {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-contact-content h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .home-contact-content h2 {
    font-size: 27px;
  }
}
</style>
