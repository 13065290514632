<template>
  <!-- Start Footer Area -->
  <footer v-if="mappedData" class="footer-area footer-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <div class="logo">
              <a :href="mappedData.recmDEtu2f7E4XAzt">
                <img
                  src="../../assets/img/lighthouse-logo-2022.svg"
                  width="184"
                  alt="logo"
                />
              </a>
            </div>
            <p>{{ mappedData.rectyRxzI0HkVAGwO }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5">
            <h3>{{ mappedData.recd9wXlZXvvf32zK }}</h3>
            <ul class="list">
              <li>
                <a href="https://www.cloudify.biz/" target="_blank">{{
                  mappedData.rech3avbPulJbWnm6
                }}</a>
              </li>
              <li>
                <a
                  href="https://www.cloudify.biz/custom-integration-and-automation"
                  target="_blank"
                  >{{ mappedData.recopP9GY1wuaWKkY }}</a
                >
              </li>
              <li>
                <a href="/features">{{ mappedData.reclla8Nuj4Ie54Tu }}</a>
              </li>
              <li>
                <a href="/#pricingSection">{{
                  mappedData.recOvtSJxcb8mm3Tz
                }}</a>
              </li>
              <!-- <li>
                <a href="#">{{ mappedData.recF7dCY4tyrJz0ik }}</a>
              </li> -->
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>{{ mappedData.recWYDcW6xzrj38ZM }}</h3>
            <ul class="list">
              <!-- <li>
                <a href="#">{{ mappedData.recvnshOliYpoXun3 }}</a>
              </li> -->
              <li>
                <a
                  href="https://www.cloudify.biz/privacy-policy"
                  target="_blank"
                  >{{ mappedData.recwfqugLrs1XYDep }}</a
                >
              </li>
              <li>
                <a
                  href="https://www.cloudify.biz/terms-and-conditions"
                  target="_blank"
                  >{{ mappedData.rec3vPwcfs0lvSf4o }}</a
                >
              </li>
              <!-- <li>
                <a href="#">{{ mappedData.recdRoTGoXC5OdsdH }}</a>
              </li> -->
              <li>
                <a href="/support">{{ mappedData.recOY9EHrFHdn7YuR }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>{{ mappedData.recwKrrVP4zG9xAGo }}</h3>

            <ul class="footer-contact-info">
              <li>
                <feather type="map-pin"></feather>
                {{ mappedData.recffF2DXmf6vgref }}
              </li>
              <li>
                <feather type="mail"></feather
                ><a href="#">{{ mappedData.recPZWTjFvrWvMQIO }}</a>
              </li>
              <!-- <li>
                <feather type="phone-call"></feather
                ><a href="#">{{ mappedData.recL3ZXC63Vesnpa5 }}</a>
              </li> -->
            </ul>
            <!-- <ul class="social-links">
              <li>
                <a
                  href="https://www.facebook.com/cloudify.biz"
                  target="_blank"
                  class="facebook"
                  ><feather type="facebook"></feather
                ></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/Cloudify_biz"
                  target="_blank"
                  class="twitter"
                  ><feather type="twitter"></feather
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/cloudify.biz/?hl=en"
                  target="_blank"
                  class="instagram"
                  ><feather type="instagram"></feather
                ></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/cloudify.biz/"
                  target="_blank"
                  class="linkedin"
                  ><feather type="linkedin"></feather
                ></a>
              </li>
            </ul> -->
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <hr class="horizontal-line" />
          <div class="copyright-area">
            <p class="copyright-area-para">
              {{ mappedData.recIimVaskJZEpM3t }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <back-to-top bottom="50px" right="50px">
      <div class="go-top"><feather type="arrow-up"></feather></div>
    </back-to-top>
  </footer>
  <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
  name: 'Footer',
  components: { BackToTop },
  data() {
    return {
      mappedData: null,
      links: null,
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const token = 'keyKn2jwHA2TSbp0B'
      const response = await fetch(
        'https://api.airtable.com/v0/app4Hy7ZEgYAhz6XL/Footer?maxRecords=100&fields%5B%5D=Footer',
        {
          method: 'get',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      const data = await response.json()
      this.mappedData = {}
      data.records.forEach((element) => {
        this.mappedData[element.id] = element.fields.Footer
      })
      const response2 = await fetch(
        'https://api.airtable.com/v0/app4Hy7ZEgYAhz6XL/Footer?maxRecords=100&fields%5B%5D=Links',
        {
          method: 'get',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      const data2 = await response2.json()
      this.links = {}
      data2.records.forEach((element) => {
        this.links[element.id] = element.fields.Links
      })
      // eslint-disable-next-line no-console
      // console.log(this.mappedData)
      // eslint-disable-next-line no-console
      // console.log(this.links)
    },
  },
}
</script>

<style>
.footer-bg {
  background-color: #0e001f;
}
.single-footer-widget h3 {
  color: #ffffff;
}
.single-footer-widget p {
  color: #ffffff;
  opacity: 0.5;
}
.single-footer-widget ul.list li a {
  color: #ffffff !important;
  opacity: 0.5;
}
.single-footer-widget ul.list li a:hover {
  color: #ffffff;
  opacity: 1;
}
.single-footer-widget ul.footer-contact-info li {
  color: #ffffff !important;
  opacity: 0.5;
}
.single-footer-widget ul.footer-contact-info li a {
  color: #ffffff !important;
  opacity: 1;
}
.single-footer-widget ul.social-links li a.facebook {
  color: #ffffff !important;
  opacity: 0.5;
  border: 1px solid #ffffff !important;
}
.single-footer-widget ul.social-links li a.facebook:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0e001f !important;
}
.single-footer-widget ul.social-links li a.twitter {
  color: #ffffff !important;
  opacity: 0.5;
  border: 1px solid #ffffff !important;
}
.single-footer-widget ul.social-links li a.twitter:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0e001f !important;
}
.single-footer-widget ul.social-links li a.instagram {
  color: #ffffff !important;
  opacity: 0.5;
  border: 1px solid #ffffff !important;
}
.single-footer-widget ul.social-links li a.instagram:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0e001f !important;
}
.single-footer-widget ul.social-links li a.linkedin {
  color: #ffffff !important;
  opacity: 0.5;
  border: 1px solid #ffffff !important;
}
.single-footer-widget ul.social-links li a.linkedin:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0e001f !important;
}
.copyright-area {
  border-top: 0px !important;
  opacity: 0.5 !important;
}
.copyright-area p {
  color: #ffffff !important;
}
.horizontal-line {
  border: 0.3px solid #ffffff;
  opacity: 0.1;
  margin-bottom: -80px;
  margin-top: 60px;
}
</style>
