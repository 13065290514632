<template>
  <div class="integromat-banner-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 image-content-cont">
          <div class="integromat-banner-content">
            <h1>
              Evolve your work by implementing Lighthouse by Cloudify with Make
            </h1>
            <p>
              Make offers you a powerful integration platform where you can link
              multiple apps and customize automation of your daily workflows
              that were earlier consuming your cost and time. Implementing Make
              with Lighthouse lets you focus more on your business and take care
              of your automation with insights, real time reporting, interactive
              dashboards, and so on.
            </p>
          </div>
        </div>

        <div class="col-lg-6 image-container">
          <div class="integromat-banner-image">
            <img
              src="../../../assets/img/integromat/LH-make-graphic-hero-right.svg"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
.integromat-banner-bg {
  background-color: #1b063e;
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 150px;
  margin-bottom: 50px;
}
.integromat-banner-content {
  max-width: 520px;
  position: relative;
  top: 70px;
}
.integromat-banner-content h1 {
  font-weight: 600;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 10px;
}
.integromat-banner-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #7592b9;
}
.button {
  background-color: #0057ff;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}
.integromat-banner-image {
  position: relative;
  width: 100%;
  height: 100%;
  top: 50px;
  margin-top: 50px;
}
.svg {
  position: relative;
  margin-top: -2px;
}
@media screen and (max-width: 992px) {
  .integromat-banner-content {
    margin-left: 0px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .integromat-banner-content p {
    font-size: 16px;
  }
  .image-content-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .integromat-banner-image {
    margin-top: 50px;
    width: 80%;
  }
  .image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .integromat-banner-content h1 {
    font-size: 42px;
  }
}
@media screen and (max-width: 768px) {
  .integromat-banner-content h1 {
    font-size: 36px;
  }
}
</style>
