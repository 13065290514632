<template>
  <div class="zapier-banner-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 image-content-cont">
          <div class="zapier-banner-content">
            <h1>
              Implementing Lighthouse as a monitoring tool for Zapier clients
            </h1>
            <p>
              Connecting Zapier with Lighthouse is your one-stop solution that
              enables real-time error reporting, retriggering of your failed
              Zaps, and a lot more. You can now automate your manual workflows
              with no coding knowledge.
            </p>
          </div>
        </div>

        <div class="col-lg-6 image-container">
          <div class="zapier-banner-image">
            <img
              src="../../../assets/img/zapier/LH-zapier-graphic-hero-right.svg"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
.zapier-banner-bg {
  background-color: #0a0b36;
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 150px;
  margin-bottom: 50px;
}
.zapier-banner-content {
  max-width: 520px;
  position: relative;
  top: 70px;
}
.zapier-banner-content h1 {
  font-weight: 600;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 10px;
}
.zapier-banner-content p {
  font-weight: 500;
  font-size: 18px;
  color: #7592b9;
}
.button {
  background-color: #0057ff;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
}
.zapier-banner-image {
  position: relative;
  width: 100%;
  height: 100%;
  top: 50px;
  margin-top: 20px;
}
.svg {
  position: relative;
  margin-top: -2px;
}
@media screen and (max-width: 992px) {
  .zapier-banner-bg {
    padding-top: 50px;
  }
  .zapier-banner-content {
    margin-left: 0px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .zapier-banner-content p {
    font-size: 16px;
  }
  .image-content-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .zapier-banner-image {
    margin-top: 50px;
    width: 80%;
  }
  .image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .zapier-banner-content h1 {
    font-size: 42px;
  }
}
@media screen and (max-width: 768px) {
  .zapier-banner-content h1 {
    font-size: 36px;
  }
}
</style>
