import axios from "axios";
let fetchedPackages;

export async function fetchAllPackages(rollbar) {
  try {
    const res = await axios.get(
      "https://cms.cloudify.biz/marketplace-packages?_limit=1000"
    );
    rollbar.info("All CMS Marketplace Packages Fetched", {
      data: res.data,
    });
    fetchedPackages = res.data;
  } catch (error) {
    rollbar.error("Error While Fetching All CMS Marketplace Packages", {
      error,
    });
  }
}

export function extractWorkflows(selectedTriggerApp, selectedActionApp) {
  try {
    // workflow list,  workflow list map {disc, id}
    let tempWorkflowList = [];
    for (let i = 0; i < fetchedPackages.length; i++) {
      if (
        (fetchedPackages[i].source.slug === selectedTriggerApp ||
          fetchedPackages[i].source.slug === selectedActionApp) &&
        (fetchedPackages[i].destination.slug === selectedTriggerApp ||
          fetchedPackages[i].destination.slug === selectedActionApp)
      ) {
        tempWorkflowList.push({
          text: fetchedPackages[i].title,
          value: fetchedPackages[i].id,
        });
      }
    }
    if (tempWorkflowList.length === 0) {
      tempWorkflowList.push({ text: "Custom Workflow", value: -1 });
    }
    return tempWorkflowList;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function extractDescription(workflowId) {
  let description = "We will create a custom workflow for you.";
  try {
    for (let i = 0; i < fetchedPackages.length; i++) {
      if (fetchedPackages[i].id === workflowId) {
        description = fetchedPackages[i].description;
      }
    }
    return description;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function extractActionApps(sourceApp) {
  let appsList = [];
  let appsCovered = [];

  try {
    for (let i = 0; i < fetchedPackages.length; i++) {
      if (
        fetchedPackages[i].source.slug === sourceApp &&
        !appsCovered.includes(fetchedPackages[i].destination.slug)
      ) {
        appsList.push({
          text: fetchedPackages[i].destination.name,
          value: fetchedPackages[i].destination.slug,
        });
        appsCovered.push(fetchedPackages[i].destination.slug);
      }

      if (
        fetchedPackages[i].destination.slug === sourceApp &&
        !appsCovered.includes(fetchedPackages[i].source.slug)
      ) {
        appsList.push({
          text: fetchedPackages[i].source.name,
          value: fetchedPackages[i].source.slug,
        });
        appsCovered.push(fetchedPackages[i].source.slug);
      }
    }
  } catch (err) {
    throw new err();
  }
  return appsList;
}
