<template>
  <div>
    <BannerSection></BannerSection>
    <div class="features-cont">
      <FeaturesSection></FeaturesSection>
    </div>
    <ContactSection></ContactSection>
  </div>
</template>

<script>
import BannerSection from './BannerSection.vue'
import FeaturesSection from './FeaturesSection.vue'
import ContactSection from './ContactSection.vue'
export default {
  name: 'Features',
  components: {
    BannerSection,
    FeaturesSection,
    ContactSection,
  },
}
</script>

<style>
.features-cont {
  margin-top: -100px;
  margin-bottom: 40px;
}
</style>
