<template>
  <div class="pricing-bg-cont" id="pricingSection">
    <div
      class="container d-flex flex-column justify-content-center align-items-center"
    >
      <h2 class="home-pricing-heading">
        Tailored Pricing for Your Unique Needs
      </h2>
      <p class="home-pricing-des">
        At Lighthouse by Cloudify, we understand that each business has its own
        unique requirements and challenges. That's why we don't have a
        one-size-fits-all pricing model. Our pricing is as bespoke as our
        service — tailored to fit the specific needs and scale of each client.
        To receive a personalized pricing plan that aligns with your business
        objectives, please contact us for a consultation.
      </p>

      <!-- <div class="d-flex flex-row mt-4 mb-3">
        <span :class="'ml-3 span-el ' + (checked ? '' : 'span-el-1')"
          >Monthly</span
        >
        <b-form-checkbox
          class="ml-3"
          v-model="checked"
          name="check-button"
          switch
        >
        </b-form-checkbox>
        <span :class="'ml-3 span-el ' + (checked ? 'span-el-1' : '')"
          >Annually</span
        >
        <select class="select-element ml-2" v-model="selectedCurrency">
          <option v-for="item in currency" :key="item.index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>

      <ul class="row unordered-list">
        <li v-for="item in pricingData" :key="item.index">
          <div class="price-card-cont">
            <img
              :src="require(`@/assets/img/pricing-icons/${item.icon}`)"
              alt="icon"
            />

            <h3>{{ item.title }}</h3>
            <p>{{ item.subTitle }}</p>
            <div class="plan-data-unordered-list">
              <p v-for="data in item.desc" :key="data.index">{{ data }}</p>
            </div>
            <h4>
              <span v-if="selectedCurrency === 'USD'"
                >${{ finalPrice['USD'][checked][item.id] }}</span
              >
              <span v-else>Kr.{{ finalPrice['DKK'][checked][item.id] }}</span>
              <span class="price-tag">/mo</span>
            </h4>
            <a
              href="/signup"
              :class="'btn ' + (item.id === 0 ? 'start-trial-button' : '')"
              >{{ item.buttonText }}</a
            >
          </div>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pricing',
  data() {
    return {
      checked: false,
      currency: ['USD', 'DKK'],
      selectedCurrency: 'USD',
      finalPrice: {
        USD: {
          true: [0, 24, 50, 149, 299],
          false: [0, 28.8, 60, 178.8, 358.8],
        },
        DKK: {
          true: [0, 149, 299, 929, 1899],
          false: [0, 176, 379, 1129, 2266],
        },
      },
      pricingData: [
        {
          id: 0,
          title: 'FREE',
          icon: 'icon-free-new-user.svg',
          subTitle: 'New User',
          desc: [
            '2 workflows',
            '1000 transactions',
            '24 hrs email response',
            '180 days Historical Data',
          ],
          buttonText: 'start trial',
        },
        {
          id: 1,
          title: 'LITE',
          icon: 'icon-lite-startups.svg',
          subTitle: 'Startups',
          desc: [
            '5 workflows',
            '5000 transactions',
            '24 hrs email response',
            '180 days Historical Data',
          ],
          buttonText: 'subscribe',
        },
        {
          id: 2,
          title: 'PRO',
          icon: 'icon-pro-growing-team.svg',
          subTitle: 'Growing Team',
          desc: [
            '10 workflows',
            '100000 transactions',
            '24 hrs email response',
            '180 days Historical Data',
          ],
          buttonText: 'subscribe',
        },
        {
          id: 3,
          title: 'EXPERT',
          icon: 'icon-expert-infrastructure.svg',
          subTitle: 'Infrastructure',
          desc: [
            '30 workflows',
            '200000 transactions',
            '8 hrs email response',
            'Unlimited Historical Data',
          ],
          buttonText: 'subscribe',
        },
        {
          id: 4,
          title: 'PREMIUM',
          icon: 'icon-premium-custom.svg',
          subTitle: 'Custom Workflows',
          desc: [
            '30 workflows',
            '1000000+ transactions',
            '8 hrs email response',
            'Unlimited Historical Data',
          ],
          buttonText: 'subscribe',
        },
      ],
    }
  },
}
</script>

<style scoped>
.pricing-bg-cont {
  margin-top: 130px;
}
.home-pricing-heading {
  color: #0f006b;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
}
.home-pricing-des {
  color: #627792;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  max-width: 700px;
}
.unordered-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
}
.price-card-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 194px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
  margin: 12px;
}
.price-card-cont h3 {
  color: #0f006b;
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
  text-transform: uppercase;
}
.price-card-cont p {
  color: #627792;
  font-size: 14px;
  font-weight: 600;
  margin-top: -10px;
}
.price-card-cont h4 {
  color: #0f006b;
  font-weight: 500;
  font-size: 32px;
  margin-top: 10px;
}
.price-card-cont a {
  background-color: #0057ff;
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  color: #ffffff;
  margin-top: 10px;
  text-transform: capitalize;
}
.plan-data-unordered-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  list-style-type: disc;
}
.plan-data-unordered-list p {
  color: #627792;
  font-weight: 400;
  font-size: 12px;
  margin-top: -15px;
}
.price-tag {
  font-size: 16px;
  font-weight: 600;
}
.select-element {
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #627792;
}
.span-el {
  font-size: 14px;
  font-weight: 400;
  color: #627792;
}
.span-el-1 {
  font-weight: 600 !important;
}
.start-trial-button {
  background-color: #02c65c !important;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-pricing-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .home-pricing-heading {
    font-size: 27px;
  }
  .price-card-cont h4 {
    font-size: 25px;
  }
  .price-card-cont h3 {
    font-size: 18px;
  }
}
</style>
