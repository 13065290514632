<template>
  <div class="home-automation-cont">
    <div class="container">
      <div class="home-automation-content-cont">
        <h2 class="home-automation-heading">
          Experience the Managed Efficiency of Lighthouse
        </h2>
        <p>
          Discover Lighthouse by Cloudify, where advanced automation meets
          expert management. Our platform extends beyond the realm of
          traditional tools, offering a seamless, managed experience for
          real-time process analytics and business monitoring. Lighthouse excels
          in simplifying complex workflows and delivering insightful analytics,
          all within a fully managed environment. Embrace a solution that takes
          the burden off your team, allowing you to focus on strategic business
          growth. Interested in transforming your business operations? Get in
          touch with us today or book a demonstration to witness the power of
          Lighthouse firsthand.
        </p>
      </div>
      <!-- <div>
        <ul class="unorderd-list">
          <li
            v-for="item in automationData"
            :key="item.index"
            class="list-item"
          >
            <div
              :class="`col-lg-5 col-md-12 order-md-none order-lg-${item.orderContent} content-cont`"
            >
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
              <a :href="item.url" target="_blank"
                ><p class="link">Learn more></p></a
              >
            </div>
            <img
              :src="require(`@/assets/img/home/${item.image}`)"
              alt="image"
              :class="`col-lg-5 col-md-12 order-lg-${item.orderImg} image`"
            />
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutomationSection',
  data() {
    return {
      automationData: [
        {
          title: 'Managed Automation',
          image: 'img-managed-automation-new.svg',
          description:
            'Now integrate multiple apps and automate complex business processes on Lighthouse. You can add advanced decision trees to your business workflows and help in data migration from app to app. ',
          url: 'https://marketplace.cloudify.biz/',
          orderImg: '1',
          orderContent: '2',
        },
        {
          title: 'Prebuilt Automation',
          image: 'img-prebuilt-automation.svg',
          description:
            'You can automate your workflows with our pre-built workflows which does not require any coding knowledge. Select from our pre-built workflows based on the process that you want to automate.',
          url: 'https://marketplace.cloudify.biz/',
          orderImg: '2',
          orderContent: '1',
        },
      ],
    }
  },
}
</script>

<style scoped>
.home-automation-cont {
  text-align: center;
  margin-top: 100px;
}
.home-automation-content-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-automation-content-cont h2 {
  font-size: 36px;
  font-weight: 600;
  color: #0f006b;
  max-width: 700px;
}
.home-automation-content-cont p {
  font-size: 14px;
  font-weight: 400;
  color: #627792;
  max-width: 700px;
}
.unorderd-list {
  list-style-type: none;
  padding: 0px;
}
.list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
}
.content-cont {
  text-align: left;
  max-width: 340px;
}
.content-cont h3 {
  color: #0f006b;
  font-size: 36px;
  font-weight: 600;
}
.content-cont p {
  color: #627792;
  font-size: 14px;
  font-weight: 400;
}
.link {
  color: #0057ff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
@media screen and (max-width: 992px) {
  .list-item {
    display: flex;
    flex-direction: column;
  }
  .content-cont {
    text-align: center;
    max-width: 500px;
  }
  .image {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .home-automation-content-cont h2 {
    font-size: 30px;
  }
  .content-cont h3 {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .home-automation-content-cont h2 {
    font-size: 27px;
  }
  .content-cont h3 {
    font-size: 27px;
  }
}
</style>
